import { faGithub, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { information } from '../content/information';
import classNames from '../utilities/class-names';
import './SocialLinks.scss';

export type SocialLinksProps = {
  layout?: 'horizontal' | 'vertical';
  showLabels?: boolean;
  showXLink?: boolean;
  showGithubLink?: boolean;
  showEmailLink?: boolean;
};

export function SocialLinks({
  layout = 'horizontal',
  showLabels = false,
  showXLink = true,
  showGithubLink = true,
  showEmailLink = true,
}: SocialLinksProps): ReactElement {
  return (
    <div
      className={classNames(
        'social-links',
        layout,
        showLabels ? 'show-labels' : 'hide-labels'
      )}
    >
      {showXLink && information.xLink && (
        <div className="social-link">
          <a href={information.xLink} target="_blank" rel="noreferrer">
            <FontAwesomeIcon
              className="social-link-icon"
              icon={faXTwitter as any}
            />
            <span className="social-link-label">{information.xLink}</span>
          </a>
        </div>
      )}
      {showGithubLink && information.githubLink && (
        <div className="social-link">
          <a href={information.githubLink} target="_blank" rel="noreferrer">
            <FontAwesomeIcon
              className="social-link-icon"
              icon={faGithub as any}
            />
            <span className="social-link-label">{information.githubLink}</span>
          </a>
        </div>
      )}
      {showEmailLink && information.emailAddress && (
        <div className="social-link">
          <a
            href={`mailto:${information.emailAddress}?subject=${information.emailSubject}`}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon className="social-link-icon" icon={faEnvelope} />
            <span className="social-link-label">
              {information.emailAddress}
            </span>
          </a>
        </div>
      )}
    </div>
  );
}

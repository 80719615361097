import { ReactElement, ReactNode } from 'react';
import { Tabs as ReactTabs, Tab, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import classNames from '../utilities/class-names';
import './Tabs.scss';

type TabsProps = {
  className?: string;
  tabs: {
    title: string;
    content: ReactNode;
  }[];
  defaultTab?: number;
};

export function Tabs({
  className,
  tabs = [],
  defaultTab = 0,
}: TabsProps): ReactElement {
  return (
    <ReactTabs className={classNames('tabs', className)}>
      <TabList>
        {tabs.map((tab, index) => (
          <Tab key={index}>{tab.title}</Tab>
        ))}
      </TabList>
      {tabs.map((tab, index) => (
        <TabPanel key={index}>{tab.content}</TabPanel>
      ))}
    </ReactTabs>
  );
}

import { useCallback, useState } from 'react';
import config from '../config';
import { Error as ApiError, SearchResults } from '../types';
import prepareHeaders from '../utilities/prepare-headers';
import { useAuth } from './auth';

const useSearch = () => {
  const { token } = useAuth();

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleError = (error: Error) => {
    setPending(false);

    if (error.name === 'AbortError') {
      return;
    }

    setError(error);
  };

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  const search = useCallback(
    async (
      query: string,
      signal?: AbortSignal
    ): Promise<SearchResults | void> => {
      setPending(true);

      return fetch(`${config.api.url}/dashboard/search?query=${query}`, {
        method: 'GET',
        headers: prepareHeaders(token),
        signal,
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as SearchResults;
        })
        .catch(handleError);
    },
    [token]
  );

  return {
    pending,
    error,
    resetError,
    search,
  };
};

export default useSearch;

import { ReactElement } from 'react';
import { SearchBar, Title, UserMenu } from '.';
import classNames from '../utilities/class-names';
import './Header.scss';

type HeaderProps = {
  showSearchBar?: boolean;
  showUserMenu?: boolean;
};

export function Header({
  showSearchBar = true,
  showUserMenu = true,
}: HeaderProps): ReactElement {
  return (
    <header
      className={classNames(
        'header',
        showSearchBar ? 'header-show-search-bar' : 'header-hide-search-bar',
        showUserMenu ? 'header-show-user-menu' : 'header-hide-user-menu'
      )}
    >
      <Title simple />
      {showSearchBar ? <SearchBar /> : <div></div>}
      {showUserMenu && <UserMenu />}
    </header>
  );
}

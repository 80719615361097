import { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import config from '../config';
import {
  Error as ApiError,
  Identity,
  IdentityMinimal,
  IdentityOrderField,
  IdentityStats,
  PaginatedRequest,
  PaginatedResponse,
} from '../types';
import pluckParams from '../utilities/pluck-params';
import prepareHeaders from '../utilities/prepare-headers';
import { useAuth } from './auth';
import { useBulletin } from './bulletin';

const REFRESH_BULLETIN_DEBOUNCE_DELAY = 1000;

const useIdentities = () => {
  const { token } = useAuth();
  const { refresh: refreshBulletin } = useBulletin();

  const debouncedRefreshBulletin = useDebouncedCallback(() => {
    refreshBulletin(true);
  }, REFRESH_BULLETIN_DEBOUNCE_DELAY);

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleError = (error: Error) => {
    setPending(false);

    if (error.name === 'AbortError') {
      return;
    }

    setError(error);
  };

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  const index = useCallback(
    async (
      options?: PaginatedRequest<IdentityOrderField> & {
        activated?: boolean;
        group?: string;
        name?: string;
        userId?: string;
      },
      signal?: AbortSignal
    ): Promise<PaginatedResponse<Identity> | void> => {
      const actualOptions = Object.assign(
        {},
        {
          page: 1,
          limit: 10,
          order: 'createdAt',
          direction: 'desc',
        },
        options ?? {}
      );

      setPending(true);

      return fetch(
        `${config.api.url}/identities?${new URLSearchParams({
          page: actualOptions.page.toString(),
          limit: actualOptions.limit.toString(),
          order: actualOptions.order,
          direction: actualOptions.direction,
          ...pluckParams(actualOptions, 'activated', 'group', 'name', 'userId'),
        })}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as PaginatedResponse<Identity>;
        })
        .catch(handleError);
    },
    [token]
  );

  const show = useCallback(
    async (
      identityId: string,
      signal?: AbortSignal
    ): Promise<Identity | void> => {
      setPending(true);

      return fetch(`${config.api.url}/identities/${identityId}`, {
        method: 'GET',
        headers: prepareHeaders(token),
        signal,
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Identity;
        })
        .catch(handleError);
    },
    [token]
  );

  const stats = useCallback(
    async (
      identityId: string,
      signal?: AbortSignal
    ): Promise<IdentityStats | void> => {
      setPending(true);

      return fetch(
        `${config.api.url}/identities/${identityId}/stats?days=${config.dashboardDays}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as IdentityStats;
        })
        .catch(handleError);
    },
    [token]
  );

  const indexEvents = useCallback(
    async (
      identityId: string,
      options?: PaginatedRequest<'createdAt'>,
      signal?: AbortSignal
    ): Promise<PaginatedResponse<Event> | void> => {
      const actualOptions = Object.assign(
        {},
        {
          page: 1,
          limit: 10,
          order: 'createdAt',
          direction: 'desc',
        },
        options ?? {}
      );

      setPending(true);

      return fetch(
        `${
          config.api.url
        }/identities/${identityId}/events?${new URLSearchParams({
          page: actualOptions.page.toString(),
          limit: actualOptions.limit.toString(),
          order: actualOptions.order,
          direction: actualOptions.direction,
        })}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as PaginatedResponse<Event>;
        })
        .catch(handleError);
    },
    [token]
  );

  const showEvent = useCallback(
    async (
      identityId: string,
      eventId: string,
      signal?: AbortSignal
    ): Promise<Event | void> => {
      setPending(true);

      return fetch(
        `${config.api.url}/identities/${identityId}/events/${eventId}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Event;
        })
        .catch(handleError);
    },
    [token]
  );

  const indexAllMinimal = useCallback(
    async (signal?: AbortSignal): Promise<IdentityMinimal[] | void> => {
      setPending(true);

      return fetch(`${config.api.url}/dashboard/identities`, {
        method: 'GET',
        headers: prepareHeaders(token),
        signal,
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return ((await response.json()).data ?? []) as IdentityMinimal[];
        })
        .catch(handleError);
    },
    [token]
  );

  const checkName = useCallback(
    async (
      group: string | null,
      name: string,
      signal?: AbortSignal
    ): Promise<boolean | void> => {
      setPending(true);

      const searchParams: {
        name: string;
        group?: string;
      } = {
        name,
      };
      if (group) {
        searchParams.group = group;
      }

      return fetch(
        `${config.api.url}/dashboard/identities?${new URLSearchParams(
          searchParams
        )}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          const responseData = await response.json();
          if (responseData) {
            // Group + name combination is already in use
            if (
              (responseData.data?.length ?? 0) > 0 &&
              responseData.data.find(
                (datum: IdentityMinimal) =>
                  datum.group === group && datum.name === name
              )
            ) {
              return false;
            }

            // Name is reserved
            if (
              responseData.reservedNames &&
              responseData.reservedNames
                .map((p: string) => p.toLowerCase().trim())
                .includes(name.toLowerCase().trim())
            ) {
              return false;
            }
          }

          return true;
        })
        .catch(handleError);
    },
    [token]
  );

  const create = useCallback(
    async (data: Partial<Identity>): Promise<Identity | void> => {
      setPending(true);

      return fetch(`${config.api.url}/identities`, {
        method: 'POST',
        headers: prepareHeaders(token),
        body: JSON.stringify(data),
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          debouncedRefreshBulletin();

          return (await response.json()) as Identity;
        })
        .catch(handleError);
    },
    [token, debouncedRefreshBulletin]
  );

  const update = useCallback(
    async (
      identityId: string,
      data: Partial<Identity>
    ): Promise<Identity | void> => {
      setPending(true);

      return fetch(`${config.api.url}/identities/${identityId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify(data),
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          debouncedRefreshBulletin();

          return (await response.json()) as Identity;
        })
        .catch(handleError);
    },
    [token, debouncedRefreshBulletin]
  );

  const activate = useCallback(
    async (identityId: string): Promise<Identity | void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/identities/${identityId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify({ activated: true }),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          debouncedRefreshBulletin();

          return (await response.json()) as Identity;
        })
        .catch(handleError);
    },
    [token, debouncedRefreshBulletin]
  );

  const deactivate = useCallback(
    async (identityId: string): Promise<Identity | void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/identities/${identityId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify({ activated: false }),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          debouncedRefreshBulletin();

          return (await response.json()) as Identity;
        })
        .catch(handleError);
    },
    [token, debouncedRefreshBulletin]
  );

  const delete_ = useCallback(
    async (identityId: string): Promise<boolean | void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/identities/${identityId}`, {
        method: 'DELETE',
        headers: prepareHeaders(token),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return false;
          }

          debouncedRefreshBulletin();

          return true;
        })
        .catch(handleError);
    },
    [token, debouncedRefreshBulletin]
  );

  return {
    pending,
    error,
    resetError,
    index,
    show,
    stats,
    indexEvents,
    showEvent,
    indexAllMinimal,
    checkName,
    create,
    update,
    activate,
    deactivate,
    delete: delete_,
  };
};

export default useIdentities;

import {
  IconDefinition,
  faLeaf,
  faTree,
  faTreeCity,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Tag } from '.';
import { Intent } from '../types';
import classNames from '../utilities/class-names';

type SubscriptionTagStyle = {
  icon: IconDefinition;
  className: string;
  label: string;
  intent?: Intent;
};

const subscriptionTagStyles: {
  [key: string]: SubscriptionTagStyle;
} = {
  free: {
    icon: faLeaf,
    className: 'subscription-tag-free',
    label: 'Free',
    intent: 'info',
  },
  developer: {
    icon: faTree,
    className: 'subscription-tag-developer',
    label: 'Developer',
    intent: 'success',
  },
  enterprise: {
    icon: faTreeCity,
    className: 'subscription-tag-enterprise',
    label: 'Enterprise',
    intent: 'warning',
  },
};

type SubscriptionTagProps = {
  subscription: string;
};

export function SubscriptionTag({
  subscription,
}: SubscriptionTagProps): ReactElement {
  let { icon, className, label, intent } = subscriptionTagStyles[subscription];

  return (
    <Tag
      show
      className={classNames('subscription-tag', className)}
      icon={icon}
      intent={intent}
    >
      {label}
    </Tag>
  );
}

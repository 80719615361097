import { faGithub, faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  faRightToBracket,
  faSpinner,
  faStar,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormEvent, ReactElement, useCallback, useId } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { Alert, Button } from '../components';
import { useAuth } from '../hooks/auth';
import { CallToAction } from '../layouts/CallToAction';
import './Login.scss';

export default function Login(): ReactElement {
  const {
    pending,
    error,
    loginWithEmailAndPassword,
    loginWithGoogle,
    loginWithGithub,
  } = useAuth();

  const navigate = useNavigate();

  const authGoogleTooltipId = `auth-google-tooltip-${useId()}`;
  const authGithubTooltipId = `auth-github-tooltip-${useId()}`;

  const handleLoginWithGoogle = useCallback(async () => {
    const result = await loginWithGoogle();

    if (result) {
      navigate('/dashboard');
    }
  }, [loginWithGoogle, navigate]);

  const handleLoginWithGithub = useCallback(async () => {
    const result = await loginWithGithub();

    if (result) {
      navigate('/dashboard');
    }
  }, [loginWithGithub, navigate]);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const formData = Object.fromEntries(
        new FormData(e.currentTarget).entries()
      );

      const result = await loginWithEmailAndPassword(
        formData.email.toString(),
        formData.password.toString()
      );

      if (result) {
        navigate('/dashboard');
      }
    },
    [loginWithEmailAndPassword, navigate]
  );

  return (
    <CallToAction
      className="login-page"
      leftPanelContent={
        <>
          <h1>Welcome back!</h1>
          <p>Don't have an account?</p>
          <Button
            link="/register"
            label="Register here"
            icon={faStar}
            secondary
          />
        </>
      }
    >
      {error && (
        <Alert intent="danger" show={!!error} closable>
          <p>{error?.message}</p>
        </Alert>
      )}
      <h1>Login</h1>
      <Button
        className="auth-button auth-button-google"
        onClick={handleLoginWithGoogle}
        icon={faGoogle as IconDefinition}
        intent="info"
        secondary
        lightMode
        data-tooltip-id={authGoogleTooltipId}
        data-tooltip-content="Authenticate with Google"
        data-tooltip-place="bottom"
      />
      <Tooltip id={authGoogleTooltipId} />
      <Button
        className="auth-button auth-button-github"
        onClick={handleLoginWithGithub}
        icon={faGithub as IconDefinition}
        intent="info"
        secondary
        lightMode
        data-tooltip-id={authGithubTooltipId}
        data-tooltip-content="Authenticate with GitHub"
        data-tooltip-place="bottom"
      />
      <Tooltip id={authGithubTooltipId} />
      {pending && (
        <div>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <section>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            type="text"
            autoComplete="email"
            required
            autoFocus
            disabled={pending}
          />
        </section>
        <section>
          <label htmlFor="password">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="password"
            required
            disabled={pending}
          />
          <p>
            <Link to="/forgot-password">Forgotten password?</Link>
          </p>
        </section>
        <section>
          <Button
            className="button-login"
            type="submit"
            intent="success"
            label="Login"
            icon={faRightToBracket}
            lightMode
            loading={pending}
            disabled={pending}
          />
        </section>
      </form>
    </CallToAction>
  );
}

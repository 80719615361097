import { clamp } from '@basementuniverse/utils';
import { ReactElement } from 'react';
import './Rating.scss';

type RatingProps = {
  rating: number;
  maxRating?: number;
  colours?: Record<string, string>;
  relativeThresholds?: boolean;
};

export function Rating({
  rating,
  maxRating = 100,
  colours = {
    '0': '#fffcf2',
  },
  relativeThresholds = false,
}: RatingProps): ReactElement {
  const clampedRating = clamp(rating, 0, maxRating);
  const colourThresholds = Object.entries(colours)
    .map(([threshold, colour]) => ({
      threshold: parseFloat(threshold),
      colour,
    }))
    .sort((a, b) => b.threshold - a.threshold);

  const maybeRelativeRating = relativeThresholds
    ? clampedRating / maxRating
    : clampedRating;
  const colour =
    colourThresholds.find(c => maybeRelativeRating >= c.threshold)?.colour ??
    '#fffcf2';

  return (
    <progress
      className="rating"
      max={maxRating}
      value={rating}
      style={{
        color: colour,
      }}
    ></progress>
  );
}

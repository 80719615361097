import { faKey } from '@fortawesome/free-solid-svg-icons';
import { ReactElement, useCallback, useMemo } from 'react';
import { SingleValue } from 'react-select';
import { DocumentationToken } from '../types';
import './DocumentationTokenSelector.scss';
import { Select, SelectOptionProps } from './Select';

type DocumentationTokenSelectorProps = {
  tokens: DocumentationToken[];
  selected?: string | null;
  onChange: (id: string | null) => void;
  disabled: boolean;
};

export function DocumentationTokenSelector({
  tokens,
  selected,
  onChange,
  disabled,
}: DocumentationTokenSelectorProps): ReactElement {
  const documentationTokenOptions: SelectOptionProps<DocumentationToken | null>[] =
    useMemo(
      () => [
        {
          label: 'None',
          value: null,
        },
        ...tokens.map(token => ({
          label: token.name,
          icon: faKey,
          value: token,
        })),
      ],
      [tokens]
    );

  const handleChangeSelected = useCallback(
    async (
      selectedOption: SingleValue<SelectOptionProps<DocumentationToken | null>>
    ) => {
      if (selectedOption) {
        onChange?.(selectedOption.value?.id ?? null);
      }
    },
    [onChange]
  );

  return (
    <div className="documentation-token-container">
      <Select
        name="documentation-token-select"
        options={documentationTokenOptions}
        defaultValue={documentationTokenOptions.find(
          option => option.value?.id === selected
        )}
        placeholder="Select a token"
        onChange={handleChangeSelected as any}
        disabled={disabled}
      />
    </div>
  );
}

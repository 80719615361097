import { Stats } from './stats';
import { User } from './user-model';

export type Identity = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  user?: User;
  group: string | null;
  name: string;
  lastLoginAt: Date | null;
  activated: boolean;
  locked?: boolean;
};

export type IdentityMinimal = {
  id: string;
  group: string | null;
  name: string;
};

export type IdentityStats = {
  events: Stats<{
    types: {
      [eventType: string]: number;
    };
  }>;
};

export const identityOrderFields = [
  'createdAt',
  'updatedAt',
  'userId',
  'group',
  'name',
  'activated',
] as const;

export type IdentityOrderField = (typeof identityOrderFields)[number];

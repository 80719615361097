import { EventType } from '../types/event-model';

const eventTypeColours: Record<EventType, string> = {
  [EventType.System]: '#e3e6e8',
  [EventType.Unknown]: '#acb3b9',
  [EventType.Test]: '#747f8b',
  [EventType.UserCreated]: '#f1ebf7',
  [EventType.UserUpdated]: '#d6c2e7',
  [EventType.UserDeleted]: '#bb99d7',
  [EventType.UserDeletedSelf]: '#a070c8',
  [EventType.UserRegistered]: '#8547b8',
  [EventType.UserForgotPassword]: '#67378f',
  [EventType.UserChangePassword]: '#4a2866',
  [EventType.TokenCreated]: '#85c3ae',
  [EventType.TokenUpdated]: '#55aa8e',
  [EventType.TokenDeleted]: '#3c7a66',
  [EventType.ListCreated]: '#f6d09a',
  [EventType.ListUpdated]: '#f1b157',
  [EventType.ListDeleted]: '#eb9214',
  [EventType.IndexCreated]: '#df6d69',
  [EventType.IndexUpdated]: '#d2322d',
  [EventType.IndexDeleted]: '#962420',
  [EventType.ItemCreated]: '#a1cfdd',
  [EventType.ItemUpdated]: '#63afc7',
  [EventType.ItemDeleted]: '#38859c',
  [EventType.ItemRestored]: '#22505e',
  [EventType.IdentityCreated]: '#cae8ca',
  [EventType.IdentityUpdated]: '#a6d8a6',
  [EventType.IdentityDeleted]: '#83c983',
  [EventType.IdentityRegistered]: '#5fb95f',
  [EventType.IdentityLoggedIn]: '#46a046',
  [EventType.IdentityLoggedOut]: '#367c36',
  [EventType.IdentityUpdatedSelf]: '#275927',
  [EventType.IdentityDeletedSelf]: '#173517',
  [EventType.SettingUpdated]: '#acb3b9',
  [EventType.SubscriptionCreated]: '#ecf5bd',
  [EventType.SubscriptionUpdated]: '#e0ee91',
  [EventType.SubscriptionDeleted]: '#d4e765',
  [EventType.SubscriptionSubscribed]: '#c7e039',
  [EventType.SubscriptionCancelled]: '#aec61f',
  [EventType.SubscriptionPaymentSucceeded]: '#879a18',
  [EventType.SubscriptionPaymentFailed]: '#606e11',
};

export default eventTypeColours;

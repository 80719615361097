import {
  faAddressCard,
  faMedal,
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement, useId } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { Button, Identicon } from '.';
import { useAuth } from '../hooks/auth';
import './UserMenu.scss';

export function UserMenu(): ReactElement {
  const { databaseUser: user, loggedIn, logout } = useAuth();
  const tooltipId = `user-menu-tooltip-${useId()}`;

  if (!loggedIn) {
    return <></>;
  }

  return (
    <>
      <button
        type="button"
        className="user-menu"
        data-tooltip-id={tooltipId}
        data-tooltip-place="bottom-end"
      >
        <Identicon name={user?.displayName ?? `Anonymous ${user?.id}`} />
      </button>
      {createPortal(
        <Tooltip id={tooltipId} clickable className="user-menu-tooltip">
          <span className="user-display-name">{user?.displayName}</span>
          <Button
            icon={faAddressCard}
            label="Profile"
            link="/profile"
            secondary
          />
          <Button
            icon={faMedal}
            label="Subscription"
            link="/manage-subscription"
            secondary
          />
          <Button
            icon={faRightFromBracket}
            label="Logout"
            onClick={logout}
            secondary
          />
        </Tooltip>,
        document.body
      )}
    </>
  );
}

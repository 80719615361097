import { faCalendar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useEffect, useId, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import config from '../config';
import classNames from '../utilities/class-names';
import formatDate from '../utilities/format-date';
import { Button } from './Button';
import './DatePicker.scss';

type DatePickerProps = {
  className?: string;
  name?: string;
  value?: Date;
  placeholder?: string;
  disabled?: boolean;
  allowEmpty?: boolean;
  minDate?: Date;
  maxDate?: Date;
  highlightDates?: Date[];
  onChange?: (value: Date | undefined) => void;
  [key: string]: any;
};

const NO_DATE_LABEL = '';

export function DatePicker({
  className,
  name,
  value,
  placeholder,
  disabled,
  allowEmpty,
  minDate,
  maxDate,
  highlightDates,
  onChange,
  ...props
}: DatePickerProps): ReactElement {
  const tooltipId = `datepicker-${useId()}`;

  const [dateValue, setDateValue] = useState<Date | undefined>(value);
  const [stringValue, setStringValue] = useState<string | undefined>(
    value ? formatDate(value, config.datePickerDateFormat) : NO_DATE_LABEL
  );

  useEffect(() => {
    setDateValue(value);
    setStringValue(
      value ? formatDate(value, config.datePickerDateFormat) : NO_DATE_LABEL
    );
  }, [value]);

  return (
    <div
      className={classNames('datepicker', className)}
      data-tooltip-id={tooltipId}
      data-tooltip-place="bottom-start"
    >
      <input
        type="text"
        className="datepicker-input"
        name={name}
        placeholder={placeholder}
        value={stringValue ?? ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setStringValue(e.target.value);

          const d = new Date(e.target.value);
          if (d instanceof Date && !isNaN(d.getTime())) {
            setDateValue(d);
          }

          onChange?.(d);
        }}
        disabled={disabled}
        role="presentation"
        autoComplete="off"
        {...props}
      />
      {allowEmpty && (
        <Button
          className="datepicker-clear-button"
          icon={faTimes}
          minimal
          onClick={() => {
            setDateValue(undefined);
            setStringValue(NO_DATE_LABEL);

            onChange?.(undefined);
          }}
        />
      )}
      <span className="datepicker-icon">
        <FontAwesomeIcon icon={faCalendar} />
      </span>
      {createPortal(
        <Tooltip
          id={tooltipId}
          clickable
          openOnClick
          className="datepicker-tooltip"
          globalCloseEvents={{
            clickOutsideAnchor: true,
            escape: true,
          }}
        >
          <DayPicker
            mode="single"
            defaultMonth={dateValue || new Date()}
            onSelect={(d: Date | undefined) => {
              if (d !== undefined) {
                setDateValue(d);
                setStringValue(formatDate(d, config.datePickerDateFormat));

                onChange?.(d);
              }
            }}
            fromDate={minDate}
            toDate={maxDate}
            modifiers={{
              selected: dateValue ? [dateValue] : [],
              highlighted: highlightDates ?? [],
            }}
            modifiersStyles={{
              selected: {
                border: '2px solid currentColor',
              },
              highlighted: {
                border: '2px dotted currentColor',
              },
            }}
          />
        </Tooltip>,
        document.body
      )}
    </div>
  );
}

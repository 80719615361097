import { identicon } from '@basementuniverse/marble-identicons';
import { ReactElement, useLayoutEffect, useRef } from 'react';
import config from '../config';
import './Identicon.scss';

type IdenticonProps = {
  name: string;
};

export function Identicon({ name }: IdenticonProps): ReactElement {
  const ref = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = '';
      ref.current.appendChild(identicon(name, config.identicons));
    }
  });

  return <span className="identicon" ref={ref}></span>;
}

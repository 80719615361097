import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { Button } from './Button';
import { Modal, ModalProps } from './Modal';

type SubscriptionLockedModalProps = ModalProps & {
  resourceType?: string;
};

export function SubscriptionLockedModal({
  resourceType,
  ...props
}: SubscriptionLockedModalProps) {
  return (
    <Modal {...props} closable dismissable small>
      <h2>Locked</h2>
      <p>This {resourceType} is locked and cannot be used or modified.</p>
      <p>
        It was locked because it exceeds the current subscription limits. It can
        be unlocked by upgrading your subscription.
      </p>
      <Button
        intent="success"
        icon={faMedal}
        label="Manage subscription"
        link="/manage-subscription"
      />
    </Modal>
  );
}

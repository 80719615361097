import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import useTransition, { TransitionStatus } from 'react-transition-state';
import classNames from '../utilities/class-names';
import './Collapse.scss';

type CollapseProps = {
  className?: string;
  header?: ReactNode | ((status: TransitionStatus) => ReactNode);
  children?: ReactNode;
  closable?: boolean;
  background?: boolean;
  expanded?: boolean;
  onCollapse?: () => void;
  [key: string]: any;
};

export function Collapse({
  className,
  header,
  children,
  closable,
  background = true,
  expanded,
  onExpand,
  onCollapse,
  ...props
}: CollapseProps): ReactElement {
  const [state, toggle] = useTransition({
    timeout: 200,
    preEnter: true,
    initialEntered: expanded,
  });

  useEffect(() => {
    toggle(expanded);
  }, [toggle, expanded]);

  const close = useCallback(() => {
    toggle(false);
    onCollapse?.();
  }, [toggle, onCollapse]);

  return (
    <div
      className={classNames(
        'collapse',
        background ? 'collapse-background' : '',
        className,
        state.status
      )}
      {...props}
    >
      {header && (
        <div className="collapse-header" onClick={() => toggle()}>
          {typeof header === 'function' ? header(state.status) : header}
        </div>
      )}
      <div className="collapse-inner">
        {closable && (
          <button
            type="button"
            className="collapse-close-button"
            onClick={() => close()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        {children}
      </div>
    </div>
  );
}

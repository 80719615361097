import {
  IconDefinition,
  faCalendar,
  faFont,
  faHashtag,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Tag } from '.';
import { IndexValueType } from '../types';
import classNames from '../utilities/class-names';

type IndexValueTypeTagStyle = {
  icon: IconDefinition;
  className: string;
  label: string;
};

const indexValueTypeTagStyles: {
  [key in IndexValueType]: IndexValueTypeTagStyle;
} = {
  string: {
    icon: faFont,
    className: 'index-value-type-tag-string',
    label: 'String',
  },
  number: {
    icon: faHashtag,
    className: 'index-value-type-tag-number',
    label: 'Number',
  },
  date: {
    icon: faCalendar,
    className: 'index-value-type-tag-date',
    label: 'Date',
  },
};

type IndexValueTypeTagProps = {
  valueType: IndexValueType;
};

export function IndexValueTypeTag({
  valueType,
}: IndexValueTypeTagProps): ReactElement {
  let { icon, className, label } = indexValueTypeTagStyles[valueType];

  return (
    <Tag
      show
      className={classNames('index-value-type-tag', className)}
      icon={icon}
    >
      {label}
    </Tag>
  );
}

import { ReactElement, useEffect, useRef, useState } from 'react';
import TokenInput from 'react-customize-token-input';
import 'react-customize-token-input/dist/react-customize-token-input.original.css';
import classNames from '../utilities/class-names';
import './ArrayInput.scss';

type ArrayInputProps = {
  className?: string;
  values?: string[];
  onChange?: (values: string[]) => void;
  disabled?: boolean;
};

export function ArrayInput({
  className,
  values,
  onChange,
  disabled,
}: ArrayInputProps): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentValues, setCurrentValues] = useState<string[]>(values || []);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const input = containerRef.current.querySelector('input');

    const handleFocus = () => {
      setFocused(true);
    };

    const handleBlur = () => {
      setFocused(false);
    };

    if (input) {
      input.addEventListener('focus', handleFocus);
      input.addEventListener('blur', handleBlur);
    }

    return () => {
      input?.removeEventListener('focus', handleFocus);
      input?.removeEventListener('blur', handleBlur);
    };
  });

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const input = containerRef.current.querySelector('input');

    if (disabled) {
      input?.setAttribute('disabled', 'disabled');
    } else {
      input?.removeAttribute('disabled');
    }
  }, [disabled]);

  return (
    <div ref={containerRef} className="array-input-container">
      <TokenInput
        className={classNames(
          'array-input',
          className,
          focused ? 'array-input-focused' : undefined,
          disabled ? 'array-input-disabled' : undefined
        )}
        tokenValues={currentValues}
        onTokenValuesChange={v => {
          setCurrentValues(v);
          onChange?.(v);
        }}
      />
    </div>
  );
}

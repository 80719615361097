import {
  faBook,
  faChartSimple,
  faClock,
  faFileCode,
  faFolder,
  faFolderOpen,
  faHome,
  faKey,
  faMedal,
  faPassport,
  faPeopleGroup,
  faTags,
  faVial,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement, useEffect, useId, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { Collapse, MenuItem } from '.';
import config from '../config';
import { useAuth } from '../hooks/auth';
import { useBulletin } from '../hooks/bulletin';
import useCachedResources from '../hooks/cached-resources';
import { ListMinimal } from '../types';
import './Menu.scss';

export function Menu(): ReactElement {
  const location = useLocation();

  const { loggedIn, databaseUser: user } = useAuth();
  const { data: bulletinData } = useBulletin();

  const tooltipId = `menu-tooltip-${useId()}`;

  const [currentListId, setCurrentListId] = useState<string | null>(null);
  const [pinnedLists, setPinnedLists] = useState<ListMinimal[]>([]);
  const { list: fetchListMinimal } = useCachedResources();

  useEffect(() => {
    setCurrentListId(
      location.pathname.startsWith('/lists/')
        ? location.pathname.split('/')[2]
        : null
    );
  }, [location.pathname]);

  useEffect(() => {
    let pinnedLists: ListMinimal[] = [];

    // If we're viewing a list and it isn't a pinned list, temporarily pin it
    // to the top of the menu
    if (
      currentListId &&
      (!bulletinData?.pinnedLists ||
        !bulletinData?.pinnedLists?.some(list => list.id === currentListId))
    ) {
      pinnedLists.push(
        fetchListMinimal(currentListId) ??
          ({
            id: currentListId,
            name: currentListId,
          } as ListMinimal)
      );
    }

    // If we've got some pinned lists, add them to the menu
    if (bulletinData?.pinnedLists) {
      pinnedLists.push(...bulletinData.pinnedLists);
    }

    setPinnedLists(pinnedLists);
  }, [currentListId, bulletinData?.pinnedLists, fetchListMinimal]);

  return (
    <>
      <nav className="menu">
        <div className="menu-inner">
          {loggedIn ? (
            <MenuItem
              icon={faHome}
              title="Dashboard"
              link="/dashboard"
              tooltipId={tooltipId}
            />
          ) : (
            <MenuItem
              icon={faHome}
              title="Home"
              link="/"
              tooltipId={tooltipId}
            />
          )}
          {loggedIn && user?.admin && config.debug.showTestPage && (
            <MenuItem
              icon={faVial}
              title="Test"
              link="/test"
              tooltipId={tooltipId}
            />
          )}
          {loggedIn && (
            <MenuItem
              icon={faFolderOpen}
              title="Lists"
              link="/lists"
              count={bulletinData?.totalNewLists}
              tooltipId={tooltipId}
              end
            />
          )}
          {loggedIn &&
            pinnedLists.map(list => (
              <div key={`pinned-list-${list.id}`}>
                <MenuItem
                  className={
                    list.id === currentListId ? 'current-collapse-header' : ''
                  }
                  icon={faFolder}
                  title={list.name}
                  link={`/lists/${list.id}`}
                  tooltipId={tooltipId}
                  end
                />
                <Collapse expanded={list.id === currentListId}>
                  <MenuItem
                    icon={faFileCode}
                    title={`${list.name} items`}
                    link={`/lists/${list.id}/items`}
                    tooltipId={tooltipId}
                  />
                  <MenuItem
                    icon={faTags}
                    title={`${list.name} indexes`}
                    link={`/lists/${list.id}/indexes`}
                    tooltipId={tooltipId}
                  />
                  <MenuItem
                    icon={faClock}
                    title={`${list.name} events`}
                    link={`/events?stream=list&modelId=${list.id}`}
                    tooltipId={tooltipId}
                  />
                </Collapse>
              </div>
            ))}
          {loggedIn && (
            <MenuItem
              icon={faKey}
              title="Tokens"
              link="/tokens"
              count={bulletinData?.totalNewTokens}
              tooltipId={tooltipId}
            />
          )}
          {loggedIn && (
            <MenuItem
              icon={faPassport}
              title="Identities"
              link="/identities"
              count={bulletinData?.totalNewIdentities}
              tooltipId={tooltipId}
            />
          )}
          {loggedIn && user?.admin && (
            <MenuItem
              icon={faPeopleGroup}
              title="Users"
              link="/users"
              count={bulletinData?.totalNewUsers}
              tooltipId={tooltipId}
            />
          )}
          {loggedIn && user?.admin && (
            <MenuItem
              icon={faChartSimple}
              title="Events"
              link="/events"
              tooltipId={tooltipId}
            />
          )}
          {loggedIn &&
            (user?.admin ? (
              <MenuItem
                icon={faMedal}
                title="Subscriptions"
                link="/subscriptions"
                tooltipId={tooltipId}
              />
            ) : (
              <MenuItem
                icon={faMedal}
                title="Subscription"
                link="/manage-subscription"
                tooltipId={tooltipId}
              />
            ))}
          {user?.admin && (
            <MenuItem
              icon={faWrench}
              title="Settings"
              link="/settings"
              tooltipId={tooltipId}
            />
          )}
          <MenuItem
            icon={faBook}
            title="Documentation"
            link="/docs"
            tooltipId={tooltipId}
          />
        </div>
      </nav>
      {createPortal(
        <Tooltip id={tooltipId} className="menu-tooltip" />,
        document.body
      )}
    </>
  );
}

import {
  faClock,
  faClockRotateLeft,
  faCodeCompare,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Button, Card, EventTypeTag, TaskBar, Value } from '.';
import config from '../config';
import { Event } from '../types';
import formatDate from '../utilities/format-date';
import './ItemVersionCard.scss';

type ItemVersionCardProps = {
  itemVersion: Event;
  currentVersion?: boolean;
  onCompareClicked?: (itemVersionId: string) => void;
  onRestoreClicked?: (
    itemVersionId: string,
    restoringVersionData: {
      eventId: string;
      date: Date;
      version?: string;
    }
  ) => void;
  [key: string]: any;
};

export function ItemVersionCard({
  itemVersion,
  currentVersion,
  onCompareClicked,
  onRestoreClicked,
  ...props
}: ItemVersionCardProps): ReactElement {
  return (
    <Card className="item-version-card" {...props}>
      <TaskBar
        left={
          <>
            <Value
              className="item-version-card-date"
              icon={faClock}
              value={formatDate(itemVersion.createdAt, config.pageDateFormat)}
              inline
              standalone
            />
            <Value
              className="item-version-card-version"
              label="Version"
              value={
                <>
                  {itemVersion.version ?? (
                    <span className="extra">No version number</span>
                  )}
                  {itemVersion.currentVersion && (
                    <span
                      className="extra"
                      style={{
                        marginLeft: '2em',
                      }}
                    >
                      (Current version)
                    </span>
                  )}
                </>
              }
              inline
              standalone
            />
          </>
        }
        right={
          <>
            <Value
              className="item-version-card-type"
              value={<EventTypeTag eventType={itemVersion.type} />}
              inline
              standalone
            />
            <Button
              icon={faCodeCompare}
              label="Compare"
              intent="info"
              onClick={() => onCompareClicked?.(itemVersion.id)}
              secondary
              disabled={currentVersion}
            />
            <Button
              icon={faClockRotateLeft}
              label="Restore"
              intent="warning"
              onClick={() =>
                onRestoreClicked?.(itemVersion.id, {
                  eventId: itemVersion.id,
                  date: itemVersion.createdAt,
                  version: itemVersion.version,
                })
              }
              secondary
              disabled={currentVersion}
            />
          </>
        }
        background={false}
      />
    </Card>
  );
}

import Terminal from '@basementuniverse/react-animated-term';
import '@basementuniverse/react-animated-term/dist/react-animated-term.css';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useEffect, useId, useState } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { AnimatedTerminalAnimation } from '../types';
import classNames from '../utilities/class-names';
import './AnimatedTerminal.scss';

const TERMINAL_ANIMATION_INTERVAL = 20;
const TERMINAL_ANIMATION_DELAY = 4000;

type AnimatedTerminalProps = {
  animations: AnimatedTerminalAnimation[];
};

export function AnimatedTerminal({
  animations,
}: AnimatedTerminalProps): ReactElement {
  const [play, setPlay] = useState(true);
  const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0);
  const [animationTimeout, setAnimationTimeout] =
    useState<NodeJS.Timeout | null>(null);

  const handlePlayClick = () => {
    const newValue = !play;

    if (newValue) {
      const nextAnimationIndex =
        currentAnimationIndex >= animations.length - 1
          ? 0
          : currentAnimationIndex + 1;

      setCurrentAnimationIndex(nextAnimationIndex);
    }

    setPlay(newValue);
  };

  const handleSelectAnimationClick = (index: number) => {
    setCurrentAnimationIndex(index);
  };

  const handleAnimationCompleted = () => {
    const nextAnimationIndex =
      currentAnimationIndex >= animations.length - 1
        ? 0
        : currentAnimationIndex + 1;

    setAnimationTimeout(
      setTimeout(() => {
        setCurrentAnimationIndex(nextAnimationIndex);
      }, TERMINAL_ANIMATION_DELAY)
    );
  };

  useEffect(() => {
    if (!play && animationTimeout) {
      clearTimeout(animationTimeout);
    }
  }, [play, animationTimeout]);

  const tooltipId = `animated-terminal-tooltip-${useId()}`;

  return (
    <>
      <div className="animated-terminal">
        <div className="animations-container">
          {animations.length > 1 && (
            <>
              <button
                className={classNames(
                  'button-animation',
                  'button-animation-play'
                )}
                onClick={handlePlayClick}
              >
                <FontAwesomeIcon icon={play ? faPause : faPlay} />
              </button>
              {animations.map((animation, index) => (
                <button
                  className={classNames(
                    'button-animation',
                    'button-animation-select',
                    `button-animation-${index}`,
                    currentAnimationIndex === index ? 'selected' : undefined
                  )}
                  key={index}
                  data-tooltip-id={tooltipId}
                  data-tooltip-content={animation.title}
                  data-tooltip-place="bottom"
                  onClick={() => handleSelectAnimationClick(index)}
                ></button>
              ))}
            </>
          )}
        </div>
        {animations && animations.length > 0 && (
          <Terminal
            lines={animations[currentAnimationIndex].lines}
            interval={TERMINAL_ANIMATION_INTERVAL}
            onComplete={handleAnimationCompleted}
          />
        )}
      </div>
      {createPortal(
        <Tooltip id={tooltipId} className="animated-terminal-tooltip" />,
        document.body
      )}
    </>
  );
}

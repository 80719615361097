import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { ReactElement, ReactNode } from 'react';
import { Button, Footer, LiquidBackground } from '../components';
import JsonPad from '../images/jsonpad.svg';
import classNames from '../utilities/class-names';
import './CallToAction.scss';
import { To } from 'react-router-dom';

type CallToActionProps = {
  className?: string;
  leftPanelContent?: ReactNode;
  children?: ReactNode;
  returnButtonLabel?: string;
  returnButtonLink?: To | -1;
  [key: string]: any;
};

export function CallToAction({
  className,
  leftPanelContent,
  children,
  returnButtonLabel = 'Back to home',
  returnButtonLink = '/',
  ...props
}: CallToActionProps): ReactElement {
  return (
    <div className={classNames('cta-layout', className)} {...props}>
      <div className="inner">
        <div className="panel-left">
          <LiquidBackground gridSize={7} />
          <img className="logo" src={JsonPad} alt="jsonpad.io" />
          <div className="panel-left-content">{leftPanelContent}</div>
        </div>
        <div className="panel-right">
          <Button
            className="button-home"
            icon={faAngleLeft}
            label={returnButtonLabel}
            link={returnButtonLink}
            minimal
            lightMode
          />
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
}

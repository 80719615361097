import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';
import {
  IconDefinition,
  faBolt,
  faFolder,
  faLock,
  faPowerOff,
  faShield,
  faStar as faStarSolid,
  faTableList,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  ResourceIdentifier,
  Switch,
  Tag,
  TaskBar,
  ValueList,
} from '.';
import config from '../config';
import theme from '../exports.module.scss';
import { List } from '../types';
import classNames from '../utilities/class-names';
import formatDate from '../utilities/format-date';
import './ListCard.scss';

type ListCardProps = {
  list: List;
  admin?: boolean;
  togglePinned?: (list: List) => void;
  handleShowSubscriptionLockedModal?: () => void;
  [key: string]: any;
};

export function ListCard({
  list,
  admin,
  togglePinned,
  handleShowSubscriptionLockedModal,
  ...props
}: ListCardProps): ReactElement {
  const [subscriptionLockedButtonShake, setSubscriptionLockedButtonShake] =
    useState(true);

  const handleSubscriptionLockedTagClick = useCallback(() => {
    handleShowSubscriptionLockedModal?.();

    setSubscriptionLockedButtonShake(false);
    setTimeout(() => {
      setSubscriptionLockedButtonShake(true);
    }, 500);
  }, [handleShowSubscriptionLockedModal]);

  return (
    <Card
      className={classNames('list-card', list.locked ? 'list-locked' : '')}
      {...props}
    >
      <div className="corner-tags-container bottom">
        <Tag
          intent="warning"
          icon={faLock}
          show={list.locked}
          onClick={handleSubscriptionLockedTagClick}
          shake={subscriptionLockedButtonShake}
        >
          Locked
        </Tag>
      </div>
      <TaskBar
        left={
          <h2>
            <FontAwesomeIcon icon={faFolder} />
            <Link to={`/lists/${list.id}`}>{list.name || 'Unnamed list'}</Link>
            {list.pathName && <span className="extra">{list.pathName}</span>}
            <Button
              icon={
                (list.pinned ? faStarSolid : faStarOutline) as IconDefinition
              }
              className={
                list.pinned ? 'list-button-pinned' : 'list-button-unpinned'
              }
              onClick={togglePinned ? () => togglePinned(list) : undefined}
              minimal
            />
          </h2>
        }
        right={
          <>
            <Switch
              name={`list-${list.id}-readonly`}
              label="Readonly"
              icon={faLock}
              color={theme.intentWarning}
              value={list.readonly}
              readonly
            />
            <Switch
              name={`list-${list.id}-realtime`}
              label="Realtime"
              icon={faBolt}
              color={theme.intentInfo}
              value={list.realtime}
              readonly
            />
            <Switch
              name={`list-${list.id}-protected`}
              label="Protected"
              icon={faShield}
              color={theme.intentDanger}
              value={list.protected}
              readonly
            />
            <Switch
              name={`list-${list.id}-indexable`}
              label="Indexable"
              icon={faTableList}
              color={theme.intentInfo}
              value={list.indexable}
              readonly
            />
            <Switch
              name={`list-${list.id}-activated`}
              label="Activated"
              icon={faPowerOff}
              color={theme.intentSuccess}
              value={list.activated}
              readonly
            />
          </>
        }
      />
      <ValueList
        values={{
          Id: <code>{list.id}</code>,
          'Created by': admin ? (
            <ResourceIdentifier
              resourceType="user"
              resource={{
                id: list?.user?.id ?? '',
                displayName: list?.user?.displayName ?? '',
              }}
              copyable
            />
          ) : undefined,
          'Created at': list
            ? formatDate(list.createdAt, config.pageDateFormat)
            : '',
          Items: list.itemCount,
        }}
      />
    </Card>
  );
}

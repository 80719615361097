import { ReactElement } from 'react';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import classNames from '../utilities/class-names';
import './JsonViewer.scss';

type JsonViewerProps = {
  className?: string;
  src?: any;
  border?: boolean;
};

export function JsonViewer({
  className,
  src,
  border = false,
}: JsonViewerProps): ReactElement {
  return (
    <div
      className={classNames(
        'json-viewer',
        className,
        border ? 'border' : undefined
      )}
    >
      <JsonView src={src} />
    </div>
  );
}

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ForwardedRef, ReactElement, forwardRef, useId } from 'react';
import { createPortal } from 'react-dom';
import ReactSelect, {
  GroupBase,
  MenuProps,
  Props,
  SingleValue as SingleValueType,
  components,
} from 'react-select';
import { Tooltip } from 'react-tooltip';
import classNames from '../utilities/class-names';
import './Select.scss';

export type SelectOptionProps<T = any> = {
  label: string;
  icon?: IconDefinition;
  extra?: string;
  value: T;
  disabled?: boolean;
};

type SelectProps = {
  className?: string;
  searchable?: boolean;
  disabled?: boolean;
  lightMode?: boolean;
  onChange?: (selected: SingleValueType<SelectOptionProps>) => void;
};

const SelectMenu = (props: MenuProps) =>
  createPortal(
    <Tooltip
      id={(props.selectProps as any).menuTooltipId}
      clickable
      openOnClick
      className="select-menu-tooltip"
      globalCloseEvents={{
        clickOutsideAnchor: true,
        escape: true,
      }}
    >
      <components.Menu {...props} />
    </Tooltip>,
    document.body
  );

const SelectOption = (props: any) => (
  <components.Option {...props}>
    {(props.data as SelectOptionProps)?.icon && (
      <span className="select-option-icon">
        <FontAwesomeIcon icon={(props.data as SelectOptionProps).icon!} />
      </span>
    )}
    {(props.data as SelectOptionProps).label}
    {(props.data as SelectOptionProps)?.extra && (
      <span className="select-extra">
        {(props.data as SelectOptionProps).extra}
      </span>
    )}
  </components.Option>
);

const SingleValue = (props: any) => (
  <components.SingleValue {...props}>
    {(props.data as SelectOptionProps)?.icon && (
      <span className="select-option-icon">
        <FontAwesomeIcon icon={(props.data as SelectOptionProps).icon!} />
      </span>
    )}
    {(props.data as SelectOptionProps).label}
    {(props.data as SelectOptionProps)?.extra && (
      <span className="select-extra">
        {(props.data as SelectOptionProps).extra}
      </span>
    )}
  </components.SingleValue>
);

export const Select = forwardRef(function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  {
    className,
    searchable = false,
    disabled,
    lightMode,
    onChange,
    ...props
  }: SelectProps & Props<Option, IsMulti, Group>,
  ref?: ForwardedRef<any>
): ReactElement {
  const tooltipId = `select-menu-${useId()}`;

  return (
    <div
      className={classNames(
        'select-container',
        lightMode ? 'select-container-light' : '',
        className
      )}
      data-tooltip-id={tooltipId}
      data-tooltip-place="bottom-start"
    >
      <ReactSelect
        ref={ref}
        classNamePrefix="select"
        components={{
          Menu: SelectMenu as any,
          Option: SelectOption as any,
          SingleValue: SingleValue as any,
        }}
        {...props}
        isDisabled={disabled}
        menuIsOpen={true}
        isSearchable={searchable}
        onChange={onChange}
        options={props.options?.map(option => ({
          ...option,
          isDisabled: (option as any).disabled,
        }))}
        // @ts-ignore
        menuTooltipId={tooltipId}
      />
    </div>
  );
});

const env = process.env.REACT_APP_ENV || 'production';

const config = {
  env,
  name: 'jsonpad',
  url: env === 'local' ? 'http://localhost:3000' : 'https://www.jsonpad.io',

  // API
  api: {
    url: env === 'local' ? 'http://localhost:8000' : 'https://api.jsonpad.io',
  },

  // Debug
  debug: {
    showTestPage: true,
    bypassRateLimit: false,
    bypassSubscription: null,
    bypassAuth: false,
    overrideUserId: null,
  },

  // Firebase SDK credentials
  firebase:
    env === 'local'
      ? {
          apiKey: 'AIzaSyCEKYor96JM2Aa2Cx77AF5rNODffU3dBGo',
          authDomain: 'jsonpad-dev.firebaseapp.com',
          projectId: 'jsonpad-dev',
          storageBucket: 'jsonpad-dev.appspot.com',
          messagingSenderId: '289503168395',
          appId: '1:289503168395:web:cdfe79ff8ab6ded911118e',
        }
      : {
          apiKey: 'AIzaSyDiEnUpZIJToRhoiSTpRSE47Zt9x7ho9Kk',
          authDomain: 'jsonpad-io.firebaseapp.com',
          projectId: 'jsonpad-io',
          storageBucket: 'jsonpad-io.appspot.com',
          messagingSenderId: '331940859212',
          appId: '1:331940859212:web:a1001ec48af83f61fbd3e9',
          measurementId: 'G-RD61NW5B08',
        },

  // Stripe
  stripe:
    env === 'local'
      ? {
          publicKey: 'pk_test_WZmzqrrudNKfXH7eMU7JBCUE',
        }
      : {
          publicKey: 'pk_live_mPstJZFekPTrTdhaJXyDlVGi',
        },

  // Dashboard & UI
  dashboardDays: 7,
  bulletinRefreshInterval: 60,
  cachedResourcesRefreshInterval: 60,
  pageDateFormat: 'D MMM YYYY HH:mm:ss',
  pageShortDateFormat: 'D MMM YYYY',
  datePickerDateFormat: 'D MMM YYYY',
  chartDateFormatDays: 'D MMM',
  chartDateFormatMinutes: 'HH:mm',
  eventsPageSize: 20,
  eventsPageHeaderDateFormat: 'D MMM YYYY',
  homePageNewsItemsLimit: 3,
  showHomePageBetaBanner: true,
  generativeAPIEnabled: true,
  generativeAPISubscriptionPlans: ['developer', 'enterprise'],

  // Config from server (should match those in server/src/constants.ts)
  statsDefaultDays: 7,
  statsMaxDays: 90,

  // Identicons
  identicons: {
    size: 42,
    fontSize: 0.5,
    initialsOffset: {
      x: 0,
      y: 1,
    },
    baseSeed: 'jsonpad',
    initialsColours: ['#31363F'],
  },
};

export default config;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const SCROLL_TARGET_CLASS_NAME = 'scroll-target';
const DEFAULT_SCROLL_TARGET_OFFSET = -70;
const DEFAULT_CURRENT_DETECTION_OFFSET = -150;
const DEBOUNCE_RESIZE_TIMEOUT = 100;

type ScrollOptions = {
  /**
   * The scrolling behavior, can be 'auto', 'instant' or 'smooth'
   */
  behavior: ScrollBehavior;

  /**
   * Offset amount in pixels when scrolling to a target
   */
  targetOffset: number;

  /**
   * Offset amount in pixels when detecting the current target
   */
  detectionOffset: number;
};

const defaultScrollOptions: ScrollOptions = {
  behavior: 'smooth',
  targetOffset: DEFAULT_SCROLL_TARGET_OFFSET,
  detectionOffset: DEFAULT_CURRENT_DETECTION_OFFSET,
};

type ScrollTarget = {
  name: string;
  top: number;
};

type ScrollState = {
  scrolledToTop: boolean;
  scrollPosition: number;
  currentScrollTarget: string;
  scrollToTarget: (target: string) => void;
};

export function useScroll(options: Partial<ScrollOptions> = {}): ScrollState {
  const actualOptions = Object.assign({}, defaultScrollOptions, options);

  const [scrollTargets, setScrollTargets] = useState<ScrollTarget[]>([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrolledToTop, setScrolledToTop] = useState(true);
  const [currentScrollTarget, setCurrentScrollTarget] = useState('');

  // Find all scroll targets on page
  function findScrollTargets(): void {
    const s = Array.from(
      document.querySelectorAll(`.${SCROLL_TARGET_CLASS_NAME}`)
    )
      .map(
        (element): ScrollTarget => ({
          name: element.getAttribute('data-name') ?? '',
          top: element.getBoundingClientRect().top + window.scrollY,
        })
      )
      .sort((a, b) => a.top - b.top)
      .reverse();
    setScrollTargets(s);
    setCurrentScrollTarget(s[s.length - 1]?.name ?? '');
  }
  const debouncedFindScrollTargets = useDebouncedCallback(
    findScrollTargets,
    DEBOUNCE_RESIZE_TIMEOUT
  );

  // Update state when the page is scrolled
  const handleScroll = useCallback(() => {
    const offset = window.scrollY;
    setScrollPosition(offset);
    setScrolledToTop(offset === 0);

    // If there are no scroll targets, try to find them again
    if (scrollTargets.length === 0) {
      findScrollTargets();
    }

    // Find the currently visible scroll target
    const scrollTarget = scrollTargets.find(
      scrollTarget => scrollTarget.top + actualOptions.detectionOffset <= offset
    );
    setCurrentScrollTarget(scrollTarget?.name ?? '');
  }, [scrollTargets, actualOptions]);

  // Register event handlers and get a list of scroll targets names & positions
  useEffect(() => {
    // If there are no scroll targets, try to find them again
    if (scrollTargets.length === 0) {
      findScrollTargets();
    }

    // Initially check if we're scrolled to the top of the page
    setScrolledToTop(window.scrollY === 0);

    // Handle scroll events
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setScrolledToTop, handleScroll, scrollTargets]);

  // Re-compute scroll targets when the window is resized
  const handleFinishedResize = useMemo(
    (...args: any[]): any => debouncedFindScrollTargets(),
    [debouncedFindScrollTargets]
  );

  useEffect(() => {
    window.addEventListener('resize', handleFinishedResize);

    return () => {
      window.removeEventListener('resize', handleFinishedResize);
    };
  }, [handleFinishedResize]);

  // Scroll to a named target
  const scrollToTarget = useCallback(
    (target: string): void => {
      const scrollTarget = scrollTargets.find(
        scrollTarget => scrollTarget.name === target
      );
      if (scrollTarget) {
        window.scrollTo({
          top: scrollTarget.top + actualOptions.targetOffset,
          behavior: actualOptions.behavior,
        });
      }
    },
    [scrollTargets, actualOptions]
  );

  // Once scroll targets are initialised, check if a scroll target hash param is specified
  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.match(/^#(.*)$/);
      if (Array.isArray(hash) && hash.length > 0) {
        scrollToTarget(hash[1]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTargets]);

  return {
    scrolledToTop,
    scrollPosition,
    currentScrollTarget,
    scrollToTarget,
  };
}

import {
  faLeaf,
  faMedal,
  faPowerOff,
  faTree,
  faTreeCity,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  ResourceIdentifier,
  SubscriptionPeriodTag,
  SubscriptionTag,
  Switch,
  TaskBar,
  ValueList,
} from '.';
import config from '../config';
import theme from '../exports.module.scss';
import { Subscription } from '../types';
import formatDate from '../utilities/format-date';
import './SubscriptionCard.scss';

type SubscriptionCardProps = {
  subscription: Subscription;
  [key: string]: any;
};

export function SubscriptionCard({
  subscription,
  ...props
}: SubscriptionCardProps): ReactElement {
  return (
    <Card className="subscription-card" {...props}>
      <TaskBar
        left={
          <div>
            <h2>
              <FontAwesomeIcon
                icon={
                  {
                    free: faLeaf,
                    developer: faTree,
                    enterprise: faTreeCity,
                  }[subscription.plan.id] ?? faMedal
                }
              />
              <Link to={`/subscriptions/${subscription.id}`}>
                {subscription.plan.name}{' '}
                <span className="extra">{subscription.period}</span>
              </Link>
            </h2>
            <ValueList
              values={{
                Id: subscription?.id ?? '',
                Created: subscription
                  ? formatDate(subscription.createdAt, config.pageDateFormat)
                  : '',
                Plan: subscription ? (
                  <SubscriptionTag subscription={subscription?.plan.id} />
                ) : (
                  <span className="extra">Unknown</span>
                ),
                Period:
                  subscription && subscription.period ? (
                    <SubscriptionPeriodTag period={subscription?.period} />
                  ) : (
                    <span className="extra">None</span>
                  ),
                User: subscription.user ? (
                  <ResourceIdentifier
                    resourceType="user"
                    resource={{
                      id: subscription.user?.id ?? '',
                      displayName: subscription.user?.displayName ?? '',
                    }}
                    copyable
                  />
                ) : undefined,
                'Current period end': subscription.currentPeriodEndAt ? (
                  formatDate(
                    subscription.currentPeriodEndAt,
                    config.pageDateFormat
                  )
                ) : (
                  <span className="extra">Never</span>
                ),
              }}
            />
          </div>
        }
        right={
          <>
            <Switch
              name={`subscription-${subscription.id}-activated`}
              label="Activated"
              icon={faPowerOff}
              color={theme.intentSuccess}
              value={subscription.activated}
              readonly
            />
          </>
        }
      />
    </Card>
  );
}

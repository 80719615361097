import { parseColor } from '@basementuniverse/parsecolor';
import ReactDiffViewer from '@basementuniverse/react-diff-viewer';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import theme from '../exports.module.scss';
import classNames from '../utilities/class-names';
import pluralize from '../utilities/pluralize';
import rgbaToColour from '../utilities/rgba-to-colour';
import './JsonCompare.scss';
import { TaskBar } from './TaskBar';

type JsonCompareProps = {
  className?: string;
  a?: any;
  b?: any;
  aHeading?: ReactNode;
  bHeading?: ReactNode;
  [key: string]: any;
};

const fadedIntentSuccess = rgbaToColour({
  ...parseColor(theme.intentSuccess),
  a: 0.2,
});

const fadedIntentDanger = rgbaToColour({
  ...parseColor(theme.intentDanger),
  a: 0.2,
});

export function JsonCompare({
  className,
  a,
  b,
  aHeading,
  bHeading,
  ...props
}: JsonCompareProps): ReactElement {
  const stringify = (json: any): string => JSON.stringify(json, null, 2);

  const [stringA, setStringA] = useState('');
  const [stringB, setStringB] = useState('');

  useEffect(() => {
    if (a) {
      setStringA(stringify(a));
    }

    if (b) {
      setStringB(stringify(b));
    }
  }, [a, b]);

  return (
    <div className={classNames('json-compare', className)} {...props}>
      <TaskBar
        left={aHeading ?? 'A'}
        right={bHeading ?? 'B'}
        background={false}
      />
      <div className="json-compare-inner">
        <ReactDiffViewer
          oldValue={stringA}
          newValue={stringB}
          splitView={true}
          codeFoldMessageRenderer={(
            total: number,
            startLine: number,
            endLine: number
          ) => (
            <span className="extra">
              Expand {total} {pluralize('line', total)}...
            </span>
          )}
          useDarkTheme
          styles={{
            variables: {
              dark: {
                diffViewerBackground: theme.brandBg1,
                diffViewerColor: theme.brandFg3,
                gutterBackground: theme.brandBg2,
                gutterColor: theme.brandFg3,
                addedBackground: fadedIntentSuccess,
                addedColor: theme.brandFg3,
                removedBackground: fadedIntentDanger,
                removedColor: theme.brandFg3,
                wordAddedBackground: theme.intentSuccess,
                wordRemovedBackground: theme.intentDanger,
                addedGutterBackground: theme.brandBg2,
                removedGutterBackground: theme.brandBg2,
                gutterBackgroundDark: theme.brandBg2,
                highlightBackground: theme.intentSuccess,
                highlightGutterBackground: theme.intentSuccess,
                codeFoldGutterBackground: theme.brandBg2,
                codeFoldBackground: theme.brandBg2,
                emptyLineBackground: theme.brandBg1,
                addedGutterColor: theme.intentSuccess,
                removedGutterColor: theme.intentDanger,
                codeFoldContentColor: theme.brandFg2,
              },
            },
          }}
        />
      </div>
    </div>
  );
}

import { randomBetween, randomIntBetween } from '@basementuniverse/utils';
import { createNoise3D } from 'simplex-noise';

export const circuit = (canvas: HTMLCanvasElement, gridSize?: number): void => {
  const GRID_SIZE = 64;
  const NOISE_SCALE = 0.003;
  const TIME_SCALE = 0.135;
  const NOISE_AMOUNT = 0.5;
  const MAX_BLIPS = 50;
  const MIN_TTL = 2;
  const MAX_TTL = 4;
  const MIN_SPEED = 1;
  const MAX_SPEED = 8;
  const BLIP_CHANCE = 0.4;
  const BLIP_SPREAD = 2.6;
  const BLIP_COLOUR = '#71bea050';
  const BLIP_TRAIL_STEP_SIZE = 4;
  const EXPLOSION_CHANCE = 0.2;
  const EXPLOSION_COLOUR = '#71bea050';
  const EXPLOSION_GROWTH_RATE = 15;
  const INPUT_STEP_TIME = 0.2;
  const INPUT_STEP_DISTANCE = 30;

  const actualGridSize = gridSize || GRID_SIZE;

  // Get a 2d context for the canvas
  const context = canvas.getContext('2d');
  if (!context) {
    throw new Error('Unable to get context');
  }

  let width = 0;
  let height = 0;
  let totalTime: number = 0;
  let lastInputTime: number = 0;
  let lastMousePosition: vec = { x: 0, y: 0 };
  let actors: Actor[] = [];

  const noise3D = createNoise3D();
  type vec = { x: number; y: number };

  // Handle mouse input
  const hammer = new Hammer.Manager(canvas, {
    recognizers: [[Hammer.Pan]],
  });
  const mouse = {
    position: { x: 0, y: 0 },
    down: false,
  };
  hammer.on('panstart', () => {
    mouse.down = true;
  });
  hammer.on('panend', () => {
    mouse.down = false;
  });
  hammer.on('pan', e => {
    const canvasBoundingRect = canvas.getBoundingClientRect();
    mouse.position.x = e.center.x - canvasBoundingRect.x;
    mouse.position.y = e.center.y - canvasBoundingRect.y;
  });

  // Handle window resize
  function resize(): void {
    canvas.width = width = canvas.clientWidth;
    canvas.height = height = canvas.clientHeight;
    draw(context!);
  }
  window.onresize = resize;
  resize();

  interface Actor {
    disposed: boolean;
    update(dt: number): void;
    draw(context: CanvasRenderingContext2D): void;
  }

  class Blip implements Actor {
    position: vec = { x: 0, y: 0 };
    velocity: vec = { x: 0, y: 0 };
    direction: vec = { x: 1, y: 0 };
    speed: number = 0;
    ttl: number = 0;
    life: number = 0;
    trail: vec[] = [];
    disposed: boolean = false;
    exploded: boolean = false;

    constructor(position: vec) {
      this.position = position;
      this.trail = [position];
      this.ttl = randomIntBetween(MIN_TTL, MAX_TTL);
      this.speed = randomIntBetween(MIN_SPEED, MAX_SPEED);
    }

    update(dt: number) {
      this.velocity = add(
        this.velocity,
        mul(getNoiseVector(this.position, totalTime), NOISE_AMOUNT)
      );
      this.direction = roundDirection8(this.velocity);
      this.position = add(this.position, mul(this.direction, this.speed));

      if (
        len(sub(this.position, this.trail[this.trail.length - 1])) >
        BLIP_TRAIL_STEP_SIZE
      ) {
        this.trail.push(cpy(this.position));
      }

      this.life += dt;
      if (this.life > this.ttl) {
        this.disposed = true;
      }

      if (!this.exploded && this.life > this.ttl - 1) {
        this.exploded = true;
        if (Math.random() <= EXPLOSION_CHANCE) {
          actors.push(new Explosion(cpy(this.position)));
        }
      }
    }

    draw(context: CanvasRenderingContext2D) {
      let alpha = 1;
      if (this.life < 0.5) {
        alpha = this.life * 2;
      }
      if (this.life > this.ttl - 1) {
        alpha = this.ttl - this.life;
      }

      context.save();
      context.globalAlpha = alpha * 0.6;
      context.globalCompositeOperation = 'lighter';
      context.fillStyle = BLIP_COLOUR;
      context.shadowColor = BLIP_COLOUR;
      context.shadowBlur = 10;
      context.translate(this.position.x, this.position.y);
      context.beginPath();
      context.arc(0, 0, 5, 0, Math.PI * 2);
      context.fill();
      context.restore();

      context.save();
      context.globalAlpha = alpha * 0.2;
      context.globalCompositeOperation = 'lighter';
      context.strokeStyle = BLIP_COLOUR;
      context.lineWidth = 8;
      context.beginPath();
      context.arc(this.trail[0].x, this.trail[0].y, 8, 0, Math.PI * 2);
      if (this.trail.length > 1) {
        context.moveTo(this.trail[1].x, this.trail[1].y);
        for (let i = 2; i < this.trail.length; i++) {
          context.lineTo(this.trail[i].x, this.trail[i].y);
        }
      }
      context.stroke();
      context.restore();
    }
  }

  class Explosion implements Actor {
    position: vec = { x: 0, y: 0 };
    ttl: number = 0;
    radius: number = 0;
    disposed: boolean = false;

    constructor(position: vec) {
      this.position = position;
      this.ttl = randomBetween(0.6, 2);
      this.radius = 8;
    }

    update(dt: number) {
      this.radius += EXPLOSION_GROWTH_RATE * dt;
      this.ttl -= dt;
      if (this.ttl <= 0) {
        this.disposed = true;
      }
    }

    draw(context: CanvasRenderingContext2D) {
      let alpha = 1;
      if (this.ttl < 1) {
        alpha = this.ttl;
      }

      context.save();
      context.globalAlpha = alpha * 0.4;
      context.strokeStyle = EXPLOSION_COLOUR;
      context.globalCompositeOperation = 'lighter';
      context.lineWidth = 12;
      context.translate(this.position.x, this.position.y);
      context.beginPath();
      context.arc(0, 0, this.radius, 0, Math.PI * 2);
      context.stroke();
      context.restore();
    }
  }

  // Initialise the simulation
  function initialise(): void {}

  // Handle user input
  function handleInput(): void {
    if (
      mouse.down &&
      lastInputTime <= 0 &&
      len(sub(mouse.position, lastMousePosition)) >= INPUT_STEP_DISTANCE
    ) {
      actors.push(new Blip(cpy(mouse.position)));
      lastInputTime = INPUT_STEP_TIME;
      lastMousePosition = cpy(mouse.position);
    }
  }

  // Update the simulation
  function update(dt: number): void {
    totalTime += dt;
    for (let actor of actors) {
      actor.update(dt);
    }

    const gridWidth = Math.ceil(width / actualGridSize);
    const gridHeight = Math.ceil(height / actualGridSize);
    for (let i = gridWidth * gridHeight; i--; ) {
      const p = gridSpiral(i);
      p.x += Math.floor(gridWidth / 2);
      p.y += Math.floor(gridHeight / 2);

      const x = p.x * actualGridSize;
      const y = p.y * actualGridSize;

      if (x < 0 || x >= width || y < 0 || y >= height) {
        continue;
      }

      let n = getNoise({ x: x + totalTime * 3, y }, totalTime) * 0.5 + 0.5;
      if (n > BLIP_CHANCE) {
        continue;
      }
      n = Math.floor(n * BLIP_SPREAD);
      if (actors.length < MAX_BLIPS) {
        for (let i = 0; i < n; i++) {
          actors.push(
            new Blip({
              x: x + (randomIntBetween(0, 4) * actualGridSize) / 4,
              y: y + (randomIntBetween(0, 4) * actualGridSize) / 4,
            })
          );
        }
      } else {
        break;
      }
    }
    actors = actors.filter(a => !a.disposed);
    lastInputTime -= dt;
  }

  // Iterate through a grid in a spiral pattern
  function gridSpiral(i: number): vec {
    let x = 0;
    let y = 0;
    let dx = 0;
    let dy = -1;
    for (let j = 0; j < i; j++) {
      if (x === y || (x < 0 && x === -y) || (x > 0 && x === 1 - y)) {
        [dx, dy] = [-dy, dx];
      }
      x += dx;
      y += dy;
    }
    return { x, y };
  }

  // Render the simulation
  function draw(context: CanvasRenderingContext2D): void {
    context.clearRect(0, 0, width, height);
    for (let actor of actors) {
      actor.draw(context);
    }
  }

  // Add 2 2d vectors
  function add(v1: vec, v2: vec): vec {
    return { x: v1.x + v2.x, y: v1.y + v2.y };
  }

  // Subtract 2 2d vectors
  function sub(v1: vec, v2: vec): vec {
    return { x: v1.x - v2.x, y: v1.y - v2.y };
  }

  // Scale a 2d vector
  function mul(v: vec, s: number): vec {
    return { x: v.x * s, y: v.y * s };
  }

  // Copy a 2d vector
  function cpy(v: vec): vec {
    return { x: v.x, y: v.y };
  }

  // Get the length of a 2d vector
  function len(v: vec): number {
    return Math.sqrt(v.x * v.x + v.y * v.y);
  }

  // Normalise a 2d vector
  function norm(v: vec): vec {
    const length = len(v);
    if (length === 0) {
      return { x: 0, y: 0 };
    }
    return { x: v.x / length, y: v.y / length };
  }

  // Get a noise value at a position and time
  function getNoise(p: vec, t: number) {
    return noise3D(p.x * NOISE_SCALE, p.y * NOISE_SCALE, t * TIME_SCALE);
  }

  // Get a 2 noise values as a vector at a position and time
  function getNoiseVector(p: vec, t: number) {
    return {
      x: noise3D(p.x * NOISE_SCALE, p.y * NOISE_SCALE, t * TIME_SCALE),
      y: noise3D(
        (p.x + 38929) * NOISE_SCALE,
        (p.y + 89147) * NOISE_SCALE,
        t * TIME_SCALE
      ),
    };
  }

  function roundDirection8(d: vec) {
    const r = (n: number): number => {
      if (n < -0.5) {
        return -1;
      }
      if (n >= 0.5) {
        return 1;
      }
      return 0;
    };

    return norm({
      x: r(d.x),
      y: r(d.y),
    });
  }

  // Start the render loop
  function loop(): void {
    handleInput();
    update(1 / 60);
    draw(context!);
    window.requestAnimationFrame(loop);
  }
  initialise();
  loop();
};

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Collapse } from '.';
import { documentationContents } from '../content/documentation-contents';
import {
  DocumentationContents,
  DocumentationContentsHeading,
  DocumentationContentsPage,
  DocumentationContentsSection,
} from '../types';
import classNames from '../utilities/class-names';
import {
  isDocumentationContentsHeading,
  isDocumentationContentsPage,
  isDocumentationContentsSection,
  isDocumentationContentsSeparator,
} from '../utilities/documentation-contents';
import './DocumentationContentsMenu.scss';

type DocumentationContentsMenuSectionProps = {
  section: DocumentationContentsSection;
};

function DocumentationContentsMenuSection({
  section,
}: DocumentationContentsMenuSectionProps): ReactElement {
  const location = useLocation();

  return (
    <a
      className={classNames(
        'documentation-contents-item-section',
        location.pathname === `/docs${section.url}`
          ? 'documentation-contents-item-active'
          : ''
      )}
      href={`/docs${section.url}`}
    >
      {section.icon && (
        <FontAwesomeIcon
          className="documentation-contents-icon"
          icon={section.icon as unknown as IconDefinition}
        />
      )}
      {section.title}
    </a>
  );
}

type DocumentationContentsMenuPageProps = {
  page: DocumentationContentsPage;
};

function DocumentationContentsMenuPage({
  page,
}: DocumentationContentsMenuPageProps): ReactElement {
  const location = useLocation();

  return (
    <a
      className={classNames(
        'documentation-contents-item-page',
        location.pathname === `/docs${page.url}`
          ? 'documentation-contents-item-active'
          : ''
      )}
      href={`/docs${page.url}`}
    >
      {page.icon && (
        <FontAwesomeIcon
          className="documentation-contents-icon"
          icon={page.icon as unknown as IconDefinition}
        />
      )}
      {page.title}
    </a>
  );
}

type DocumentationContentsMenuHeadingProps = {
  heading: DocumentationContentsHeading;
};

function DocumentationContentsMenuHeading({
  heading,
}: DocumentationContentsMenuHeadingProps): ReactElement {
  return <h2 className="documentation-contents-heading">{heading.title}</h2>;
}

function DocumentationContentsMenuSeparator(): ReactElement {
  return <hr className="documentation-contents-separator" />;
}

export function DocumentationContentsMenu(): ReactElement {
  const location = useLocation();

  const getActiveItemSections = useCallback(
    (currentPathname: string): string[] => {
      const getActiveItemSectionRecursive = (
        contents: DocumentationContents
      ): string[] => {
        for (let i = 0; i < contents.length; i++) {
          const item = contents[i];
          if (isDocumentationContentsSection(item)) {
            const sectionResult = getActiveItemSectionRecursive(item.contents);
            if (sectionResult.length > 0) {
              return [item.title, ...sectionResult];
            }
            if (`/docs${item.url}` === currentPathname) {
              return [item.title];
            }
          } else if (
            isDocumentationContentsPage(item) &&
            `/docs${item.url}` === currentPathname
          ) {
            return [item.title];
          }
        }

        return [];
      };

      return getActiveItemSectionRecursive(documentationContents);
    },
    []
  );

  const buildMenu = (
    contents: DocumentationContents,
    indexPrefix: string = ''
  ): ReactElement => {
    return (
      <ul>
        {contents.map((item, index) => (
          <li key={`${indexPrefix}_${index}`}>
            {isDocumentationContentsSection(item) && (
              <Collapse
                className="documentation-contents-section-collapse"
                background={false}
                closable={false}
                expanded={getActiveItemSections(location.pathname).includes(
                  item.title
                )}
                header={<DocumentationContentsMenuSection section={item} />}
              >
                {buildMenu(
                  item.contents,
                  indexPrefix ? `${indexPrefix}_${index}` : `${index}`
                )}
              </Collapse>
            )}
            {isDocumentationContentsPage(item) && (
              <DocumentationContentsMenuPage page={item} />
            )}
            {isDocumentationContentsHeading(item) && (
              <DocumentationContentsMenuHeading heading={item} />
            )}
            {isDocumentationContentsSeparator(item) && (
              <DocumentationContentsMenuSeparator />
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <nav className="documentation-contents-menu">
      <div className="documentation-contents-menu-inner">
        {buildMenu(documentationContents)}
      </div>
    </nav>
  );
}

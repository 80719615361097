import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import classNames from '../utilities/class-names';
import './IconSprite.scss';

export type IconSpriteProps = {
  className?: string;
  layers: {
    name: string;
    icon: IconDefinition;
    style?: Record<string, any>;
  }[];
  [key: string]: any;
};

export function IconSprite({
  className,
  layers,
  ...props
}: IconSpriteProps): ReactElement {
  return (
    <div
      className={classNames(
        'icon-sprite',
        className,
        ...Object.entries(props)
          .filter(([, value]) => !!value)
          .map(([key]) => key)
      )}
    >
      {layers.map(({ name, icon, style }) => (
        <div
          key={name}
          className={classNames('icon-sprite-layer', name)}
          style={style}
        >
          <FontAwesomeIcon icon={icon} />
        </div>
      ))}
    </div>
  );
}

import { faAsterisk, faPassport } from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import {
  Card,
  ResourceIdentifier,
  Tag,
  TokenPermissionActionTag,
  TokenPermissionModeTag,
  TokenPermissionResourceTypeTag,
  Value,
  ValueList,
} from '.';
import {
  IdentityMinimal,
  IndexMinimal,
  ItemMinimal,
  ListMinimal,
  Permission,
} from '../types';
import capitalize from '../utilities/capitalize';
import './TokenPermissionCard.scss';

type TokenPermissionCardProps = {
  index: number;
  permission: Permission;
  fetchListMinimal?: (id: string) => ListMinimal | null;
  fetchItemMinimal?: (id: string) => ItemMinimal | null;
  fetchIndexMinimal?: (id: string) => IndexMinimal | null;
  fetchIdentityMinimal?: (id: string) => IdentityMinimal | null;
  [key: string]: any;
};

function tokenPermissionName(permission: Permission): string {
  if (permission.action === '*') {
    return `${capitalize(permission.mode)} All`;
  }

  return `${capitalize(permission.mode)} ${capitalize(
    permission.action
  )} ${capitalize(permission.resourceType!)}`;
}

export function TokenPermissionCard({
  index,
  permission,
  fetchListMinimal,
  fetchItemMinimal,
  fetchIndexMinimal,
  fetchIdentityMinimal,
  ...props
}: TokenPermissionCardProps): ReactElement {
  return (
    <Card className="token-permission-card" {...props}>
      <h3>
        <span className="extra">#{index + 1}</span>{' '}
        {tokenPermissionName(permission)}
      </h3>
      <ValueList>
        <Value
          className="token-permission-card-mode"
          label="Mode"
          value={
            <>
              <TokenPermissionModeTag mode={permission.mode} />
            </>
          }
        />
        <Value
          className="token-permission-card-action"
          label="Action"
          value={
            <>
              <TokenPermissionActionTag action={permission.action} />
            </>
          }
        />
        {permission.resourceType && (
          <Value
            className="token-permission-card-resource-type"
            label="Resource type"
            value={
              <>
                <TokenPermissionResourceTypeTag
                  resourceType={permission.resourceType}
                />
              </>
            }
          />
        )}
        {permission.listIds && (
          <Value
            className="token-permission-card-lists"
            label="Lists"
            value={
              <>
                {permission.listIds.map(listId =>
                  listId === '*' ? (
                    <Tag
                      show
                      icon={faAsterisk}
                      className="token-permission-card-tag-all-lists"
                    >
                      All lists
                    </Tag>
                  ) : (
                    <ResourceIdentifier
                      key={listId}
                      resourceType="list"
                      resource={fetchListMinimal?.(listId) ?? null}
                      wrap
                    />
                  )
                )}
              </>
            }
          />
        )}
        {permission.itemIds && (
          <Value
            className="token-permission-card-items"
            label="Items"
            value={
              <>
                {permission.itemIds.map(itemId =>
                  itemId === '*' ? (
                    <Tag
                      show
                      icon={faAsterisk}
                      className="token-permission-card-tag-all-items"
                    >
                      All items
                    </Tag>
                  ) : (
                    <ResourceIdentifier
                      key={itemId}
                      resourceType="item"
                      resource={fetchItemMinimal?.(itemId) ?? null}
                      wrap
                    />
                  )
                )}
              </>
            }
          />
        )}
        {permission.indexIds && (
          <Value
            className="token-permission-card-indexes"
            label="Indexes"
            value={
              <>
                {permission.indexIds.map(indexId =>
                  indexId === '*' ? (
                    <Tag
                      show
                      icon={faAsterisk}
                      className="token-permission-card-tag-all-indexes"
                    >
                      All indexes
                    </Tag>
                  ) : (
                    <ResourceIdentifier
                      key={indexId}
                      resourceType="index"
                      resource={fetchIndexMinimal?.(indexId) ?? null}
                      wrap
                    />
                  )
                )}
              </>
            }
          />
        )}
        {permission.identityIds && (
          <Value
            className="token-permission-card-identities"
            label="Identities"
            value={
              <>
                {permission.identityIds.map(identityId =>
                  identityId === '*' ? (
                    <Tag
                      show
                      icon={faAsterisk}
                      className="token-permission-card-tag-all-identities"
                    >
                      All identities
                    </Tag>
                  ) : (
                    <ResourceIdentifier
                      key={identityId}
                      resourceType="identity"
                      resource={fetchIdentityMinimal?.(identityId) ?? null}
                      wrap
                    />
                  )
                )}
              </>
            }
          />
        )}
        {permission.groups && (
          <Value
            className="token-permission-card-groups"
            label="Groups"
            value={
              <>
                {permission.groups.map(group =>
                  group === '*' ? (
                    <Tag
                      show
                      icon={faAsterisk}
                      className="token-permission-card-tag-all-groups"
                    >
                      All groups
                    </Tag>
                  ) : (
                    <Tag
                      show
                      icon={faPassport}
                      className="token-permission-card-tag-group"
                    >
                      {group}
                    </Tag>
                  )
                )}
              </>
            }
          />
        )}
      </ValueList>
    </Card>
  );
}

import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { IconDefinition, faBan } from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Tag } from '.';
import { Intent, PermissionMode } from '../types';
import classNames from '../utilities/class-names';

type TokenPermissionModeTagStyle = {
  icon: IconDefinition;
  className: string;
  label: string;
  intent?: Intent;
};

const tokenPermissionModeTagStyles: {
  [key in PermissionMode]: TokenPermissionModeTagStyle;
} = {
  allow: {
    icon: faCircleCheck as IconDefinition,
    className: 'token-permission-mode-tag-allow',
    label: 'Allow',
    intent: 'success',
  },
  block: {
    icon: faBan,
    className: 'token-permission-mode-tag-block',
    label: 'Block',
    intent: 'danger',
  },
};

type TokenPermissionModeTagProps = {
  mode: PermissionMode;
};

export function TokenPermissionModeTag({
  mode,
}: TokenPermissionModeTagProps): ReactElement {
  let { icon, className, label, intent } = tokenPermissionModeTagStyles[mode];

  return (
    <Tag
      show
      className={classNames('token-permission-mode-tag', className)}
      icon={icon}
      intent={intent}
    >
      {label}
    </Tag>
  );
}

import config from '../config';

export default function prepareHeaders(
  token?: string | null,
  headers?: Record<string, string>
): Record<string, string> {
  const result: Record<string, string> = {
    'Content-Type': 'application/json',
    ...(headers ?? {}),
  };

  if (token) {
    result['x-auth-token'] = token;
  }

  if (config.debug.bypassRateLimit) {
    result['x-api-bypass-rate-limiting'] = 'true';
  }

  if (config.debug.bypassSubscription) {
    result['x-api-bypass-subscription'] = config.debug.bypassSubscription;
  }

  if (config.debug.overrideUserId) {
    result['x-auth-override-user-id'] = config.debug.overrideUserId;
  }

  if (config.env === 'local') {
    result['cache-control'] = 'no-cache';
    result['pragma'] = 'no-cache';
    result['expires'] = '0';
  }

  return result;
}

import { Permission } from './permission';
import { Stats } from './stats';
import { User } from './user-model';

export type Token = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  user?: User;
  name: string;
  description: string;
  permissions: Permission[];
  ips: string[] | null;
  token: string;
  expiresAt: Date | null;
  activated: boolean;
  locked?: boolean;
};

export type TokenMinimal = {
  id: string;
  name: string;
};

export type TokenStats = {
  events: Stats<{
    types: {
      [eventType: string]: number;
    };
  }>;
};

export const tokenOrderFields = [
  'createdAt',
  'updatedAt',
  'name',
  'expiresAt',
  'activated',
] as const;

export type TokenOrderField = (typeof tokenOrderFields)[number];

import { ReactElement, useEffect, useRef } from 'react';
import { circuit } from '../utilities/circuit';
import './CircuitBackground.scss';

type CircuitBackgroundProps = {
  gridSize?: number;
};

export function CircuitBackground({
  gridSize,
}: CircuitBackgroundProps): ReactElement {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas !== null) {
      circuit(canvas, gridSize);
    }
  }, [gridSize]);

  return <canvas id="circuit-background" ref={canvasRef}></canvas>;
}

import { useContext } from 'react';
import { DocumentationTokenContext } from '../contexts/documentation-token';
import { DocumentationTokenContextState } from '../types';

export function useDocumentationToken(): DocumentationTokenContextState {
  const context = useContext(DocumentationTokenContext);

  if (context === undefined) {
    throw new Error(
      'useDocumentationToken must be used within a DocumentationTokenProvider'
    );
  }

  return context;
}

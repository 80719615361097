import { List } from './list-model';
import { Stats } from './stats';

export type Item = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  list?: List;
  description: string;
  data: any;
  size: number;
  version: string;
  readonly: boolean;
  activated: boolean;
  locked?: boolean;
};

export type ItemMinimal = {
  id: string;
  alias: string;
  listId: string;
};

export type ItemStats = {
  events: Stats<{
    types: {
      [eventType: string]: number;
    };
  }>;
};

export const itemOrderFields = [
  'createdAt',
  'updatedAt',
  'alias',
  'readonly',
  'activated',
] as const;

export type ItemOrderField = (typeof itemOrderFields)[number];

import {
  IconDefinition,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import classNames from '../utilities/class-names';
import './Switch.scss';

type SwitchProps = {
  name?: string;
  label?: string;
  icon?: IconDefinition;
  color?: string;
  value?: boolean;
  loading?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (on: boolean) => void;
  [key: string]: any;
};

export function Switch({
  name,
  label,
  icon,
  color = '#fffcf2',
  value,
  loading,
  disabled,
  readonly,
  onChange,
  ...props
}: SwitchProps): ReactElement {
  const [on, setOn] = useState(!!value);

  useEffect(() => {
    setOn(!!value);
  }, [value]);

  const handleChange = useCallback(
    (currentValue: boolean) => {
      setOn(currentValue);
      onChange?.(currentValue);
    },
    [onChange]
  );

  const actualIcon = loading ? faCircleNotch : icon;

  return (
    <label
      className={classNames(
        'switch',
        disabled ? 'switch-disabled' : '',
        readonly ? 'switch-readonly' : '',
        label ? 'switch-has-label' : 'switch-no-label'
      )}
    >
      <input
        type="checkbox"
        name={name}
        checked={on}
        disabled={disabled}
        readOnly={readonly}
        onChange={e => !readonly && handleChange(e.target.checked)}
        {...props}
      />
      <div className="switch-slider" style={{ color }}>
        {actualIcon && <FontAwesomeIcon icon={actualIcon} spin={!!loading} />}
      </div>
      {label && <span className="switch-label">{label ?? ''}</span>}
    </label>
  );
}

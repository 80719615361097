import {
  IconDefinition,
  faBug,
  faFileCode,
  faFolder,
  faKey,
  faQuestionCircle,
  faServer,
  faSliders,
  faTags,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Tag } from '.';
import { EventStream } from '../types';
import classNames from '../utilities/class-names';

type EventStreamTagStyle = {
  icon: IconDefinition;
  className: string;
  label: string;
};

const eventStreamTagStyles: {
  [key in EventStream]: EventStreamTagStyle;
} = {
  system: {
    icon: faServer,
    className: 'event-stream-tag-system',
    label: 'System',
  },
  unknown: {
    icon: faQuestionCircle,
    className: 'event-stream-tag-unknown',
    label: 'Unknown',
  },
  test: {
    icon: faBug,
    className: 'event-stream-tag-test',
    label: 'Test',
  },
  user: {
    icon: faUserAlt,
    className: 'event-stream-tag-user',
    label: 'User',
  },
  token: {
    icon: faKey,
    className: 'event-stream-tag-token',
    label: 'Token',
  },
  list: {
    icon: faFolder,
    className: 'event-stream-tag-list',
    label: 'List',
  },
  index: {
    icon: faTags,
    className: 'event-stream-tag-index',
    label: 'Index',
  },
  item: {
    icon: faFileCode,
    className: 'event-stream-tag-item',
    label: 'Item',
  },
  setting: {
    icon: faSliders,
    className: 'event-stream-tag-setting',
    label: 'Setting',
  },
};

type EventStreamTagProps = {
  eventStream: EventStream;
};

export function EventStreamTag({
  eventStream,
}: EventStreamTagProps): ReactElement {
  let { icon, className, label } = eventStreamTagStyles[eventStream];

  return (
    <Tag show className={classNames('event-stream-tag', className)} icon={icon}>
      {label}
    </Tag>
  );
}

import {
  IconDefinition,
  faCalendar,
  faEarthAmericas,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Tag } from '.';
import { Intent, SubscriptionPeriod } from '../types';
import classNames from '../utilities/class-names';

type SubscriptionPeriodTagStyle = {
  icon: IconDefinition;
  className: string;
  label: string;
  intent?: Intent;
};

const subscriptionPeriodTagStyles: {
  [key: string]: SubscriptionPeriodTagStyle;
} = {
  monthly: {
    icon: faCalendar,
    className: 'subscription-period-tag-monthly',
    label: 'Monthly',
    intent: 'info',
  },
  annual: {
    icon: faEarthAmericas,
    className: 'subscription-period-tag-annual',
    label: 'Annual',
    intent: 'success',
  },
};

type SubscriptionPeriodTagProps = {
  period: SubscriptionPeriod;
};

export function SubscriptionPeriodTag({
  period,
}: SubscriptionPeriodTagProps): ReactElement {
  let { icon, className, label, intent } = subscriptionPeriodTagStyles[period];

  return (
    <Tag
      show
      className={classNames('subscription-period-tag', className)}
      icon={icon}
      intent={intent}
    >
      {label}
    </Tag>
  );
}

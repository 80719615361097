import { pluck } from '@basementuniverse/utils';
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import config from '../config';
import { useAuth } from '../hooks/auth';
import { useLocalStorage } from '../hooks/local-storage';
import {
  Error as ApiError,
  DocumentationToken,
  DocumentationTokenContextState,
  PaginatedResponse,
  Token,
} from '../types';
import prepareHeaders from '../utilities/prepare-headers';

export const DocumentationTokenContext = createContext<
  DocumentationTokenContextState | undefined
>(undefined);

export function DocumentationTokenProvider({
  children,
}: {
  children?: ReactNode;
}): ReactElement {
  const { token, loggedIn } = useAuth();

  const [pending, setPending] = useState(false);
  const [tokens, setTokens] = useState<DocumentationToken[]>([]);
  const [selected, setSelected] = useLocalStorage(
    'selected-documentation-token',
    null
  );
  const [error, setError] = useState<Error | null>(null);

  const handleError = (error: Error) => {
    setPending(false);

    if (error.name === 'AbortError') {
      return;
    }

    setError(error);
  };

  const fetchTokens = useCallback(
    async (signal?: AbortSignal): Promise<void> => {
      if (!loggedIn) {
        return;
      }

      setPending(true);
      setError(null);

      const options = {
        page: 1,
        limit: 10,
        order: 'createdAt',
        direction: 'desc',
        activated: true,
      };

      fetch(
        `${config.api.url}/tokens?${new URLSearchParams(
          Object.fromEntries(
            Object.entries(options).map(([key, value]) => [
              key,
              value.toString(),
            ])
          )
        )}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          const data = (await response.json()) as PaginatedResponse<Token>;

          setTokens(
            data.data.map(token => pluck(token, 'id', 'name', 'token'))
          );
          setError(null);
        })
        .catch(handleError);
    },
    [token, loggedIn]
  );

  const refresh = useCallback(async (): Promise<void> => {
    await fetchTokens();
  }, [fetchTokens]);

  const select = useCallback(
    async (id: string | null): Promise<void> => {
      if (!tokens || tokens.length === 0) {
        return;
      }

      if (id === null) {
        setSelected(null);
        return;
      }

      const token = tokens.find(token => token.id === id);

      if (!token) {
        return;
      }

      setSelected(token.id);
    },
    [tokens, setSelected]
  );

  const clear = () => {
    setSelected(null);
    setTokens([]);
  };

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <DocumentationTokenContext.Provider
      value={{
        pending,
        error,
        tokens,
        selected,
        select,
        clear,
        refresh,
      }}
    >
      {children}
    </DocumentationTokenContext.Provider>
  );
}

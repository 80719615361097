import {
  DocumentationContentsHeading,
  DocumentationContentsPage,
  DocumentationContentsSection,
  DocumentationContentsSeparator,
} from '../types';

export function isDocumentationContentsSection(
  item: any
): item is DocumentationContentsSection {
  return (
    typeof item === 'object' &&
    'title' in item &&
    'contents' in item &&
    item.contents !== undefined
  );
}

export function isDocumentationContentsPage(
  item: any
): item is DocumentationContentsPage {
  return (
    typeof item === 'object' &&
    'url' in item &&
    'title' in item &&
    !('contents' in item)
  );
}

export function isDocumentationContentsHeading(
  item: any
): item is DocumentationContentsHeading {
  return (
    typeof item === 'object' &&
    'title' in item &&
    !('url' in item) &&
    !('contents' in item)
  );
}

export function isDocumentationContentsSeparator(
  item: any
): item is DocumentationContentsSeparator {
  return item === '-';
}

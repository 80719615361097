import { ReactElement, useEffect, useRef } from 'react';
import { liquid } from '../utilities/liquid';
import './LiquidBackground.scss';

type LiquidBackgroundProps = {
  gridSize?: number;
};

export function LiquidBackground({
  gridSize,
}: LiquidBackgroundProps): ReactElement {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas !== null) {
      liquid(canvas, gridSize);
    }
  }, [gridSize]);

  return <canvas id="liquid-background" ref={canvasRef}></canvas>;
}

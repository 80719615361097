import { ReactElement } from 'react';
import Fl3xImage from '../images/fl3x.svg';
import './Footer.scss';

type FooterProps = {
  showFl3xButton?: boolean;
};

export function Footer({ showFl3xButton = true }: FooterProps): ReactElement {
  return (
    <footer className="footer">
      {showFl3xButton && (
        <div className="fl3x-button">
          <a href="https://fl3x.tech/" target="_blank" rel="noreferrer">
            <img src={Fl3xImage} alt="fl3x" />
          </a>
        </div>
      )}
    </footer>
  );
}

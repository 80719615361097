import {
  IconDefinition,
  faCheck,
  faCircleNotch,
  faExclamation,
  faInfo,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, ReactNode } from 'react';
import { Intent } from '../types';
import classNames from '../utilities/class-names';
import './DocumentationFakeButton.scss';

type DocumentationFakeButtonStyle = {
  icon: IconDefinition;
  className: string;
};

const documentationFakeButtonStyles: {
  [key in Intent]: DocumentationFakeButtonStyle;
} = {
  info: {
    icon: faInfo,
    className: 'button-info',
  },
  success: {
    icon: faCheck,
    className: 'button-success',
  },
  warning: {
    icon: faExclamation,
    className: 'button-warning',
  },
  danger: {
    icon: faTimes,
    className: 'button-danger',
  },
};

export type DocumentationFakeButtonProps = {
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  intent?: Intent;
  icon?: IconDefinition;
  label?: string;
  children?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  secondary?: boolean;
  minimal?: boolean;
  lightMode?: boolean;
  [key: string]: any;
};

export function DocumentationFakeButton({
  className,
  type = 'button',
  intent,
  icon,
  label,
  children,
  loading,
  disabled,
  secondary,
  minimal,
  lightMode,
  ...props
}: DocumentationFakeButtonProps): ReactElement {
  let style: DocumentationFakeButtonStyle | null = null;
  if (intent) {
    style = documentationFakeButtonStyles[intent];
  }

  let actualIcon = icon ?? style?.icon;
  if (loading) {
    actualIcon = faCircleNotch;
  }

  return (
    <button
      type={type}
      disabled={!!disabled}
      className={classNames(
        'documentation-fake-button',
        secondary ? 'button-secondary' : 'button-primary',
        minimal ? 'button-minimal' : '',
        lightMode ? 'button-light' : '',
        style?.className,
        className,
        label ? '' : 'button-icon-only'
      )}
      {...props}
    >
      {actualIcon && (
        <span className="button-icon">
          <FontAwesomeIcon icon={actualIcon} spin={!!loading} />
        </span>
      )}
      {label ?? ''}
      {children}
    </button>
  );
}

import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import React, { ReactElement, ReactNode } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useScroll } from '../hooks/scroll';
import classNames from '../utilities/class-names';
import { Button } from './Button';
import './LinkableHeader.scss';
import { ScrollTarget } from './ScrollTarget';

type LinkableHeaderProps = {
  className?: string;
  id: string;
  level: 1 | 2 | 3 | 4 | 5 | 6;
  children: ReactNode;
};

export function LinkableHeader({
  className,
  id,
  level,
  children,
}: LinkableHeaderProps): ReactElement {
  useScroll();

  return React.createElement(
    `h${level}`,
    {
      id,
      className: classNames('linkable-header', className),
    },
    <>
      <ScrollTarget name={id} />
      {children}
      <CopyToClipboard text={`${window.location.href.split('#')[0]}#${id}`}>
        <Button
          className="linkable-header-copy-to-clipboard-button"
          icon={faHashtag}
          minimal
        />
      </CopyToClipboard>
    </>
  );
}

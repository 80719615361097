import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export type PaginatedRequest<T = ''> = {
  page?: number;
  limit?: number;
  order?: T;
  direction?: 'asc' | 'desc';
};

export type PaginatedResponse<T> = {
  page: number;
  limit: number;
  total: number;
  data: T[];
};

export type OrderSelectValue<T> = {
  order: T;
  direction: OrderDirection;
};

export type OrderSelectOption<T> = {
  label: string;
  extra?: string;
  icon?: IconDefinition;
  value: OrderSelectValue<T>;
  default?: boolean;
};

export const orderDirections = ['asc', 'desc'] as const;

export type OrderDirection = (typeof orderDirections)[number];

export const OrderDirectionQueryParam = {
  encode: (value?: OrderDirection | null): string | undefined =>
    value ? (value as string) : undefined,

  decode: (
    value?: string | (string | null)[] | null
  ): OrderDirection | undefined =>
    value && orderDirections.includes(value as any)
      ? (value as OrderDirection)
      : undefined,
};

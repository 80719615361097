import { ReactElement, ReactNode } from 'react';
import { Footer, Header, Menu } from '../components';
import { useAuth } from '../hooks/auth';
import classNames from '../utilities/class-names';
import './Dashboard.scss';

type DashboardProps = {
  className?: string;
  children?: ReactNode;
  [key: string]: any;
};

export function Dashboard({
  className,
  children,
  ...props
}: DashboardProps): ReactElement {
  const { loggedIn } = useAuth();

  return (
    <div className={classNames('dashboard-layout', className)} {...props}>
      <Header showUserMenu={!!loggedIn} />
      <Menu />
      <div className="dashboard-content">{children}</div>
      <Footer />
    </div>
  );
}

import {
  faFolder,
  faLock,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, ResourceIdentifier, Switch, Tag, TaskBar, ValueList } from '.';
import config from '../config';
import theme from '../exports.module.scss';
import { Identity } from '../types';
import classNames from '../utilities/class-names';
import formatDate from '../utilities/format-date';
import './IdentityCard.scss';

type IdentityCardProps = {
  identity: Identity;
  admin?: boolean;
  handleShowSubscriptionLockedModal?: () => void;
  [key: string]: any;
};

export function IdentityCard({
  identity,
  admin,
  handleShowSubscriptionLockedModal,
  ...props
}: IdentityCardProps): ReactElement {
  const [subscriptionLockedButtonShake, setSubscriptionLockedButtonShake] =
    useState(true);

  const handleSubscriptionLockedTagClick = useCallback(() => {
    handleShowSubscriptionLockedModal?.();

    setSubscriptionLockedButtonShake(false);
    setTimeout(() => {
      setSubscriptionLockedButtonShake(true);
    }, 500);
  }, [handleShowSubscriptionLockedModal]);

  return (
    <Card
      className={classNames(
        'identity-card',
        identity.locked ? 'identity-locked' : ''
      )}
      {...props}
    >
      <div className="corner-tags-container bottom">
        <Tag
          intent="warning"
          icon={faLock}
          show={identity.locked}
          onClick={handleSubscriptionLockedTagClick}
          shake={subscriptionLockedButtonShake}
        >
          Locked
        </Tag>
      </div>
      <TaskBar
        left={
          <h2>
            <FontAwesomeIcon icon={faFolder} />
            <Link to={`/identities/${identity.id}`}>{identity.name}</Link>
          </h2>
        }
        right={
          <>
            <Switch
              name={`identity-${identity.id}-activated`}
              label="Activated"
              icon={faPowerOff}
              color={theme.intentSuccess}
              value={identity.activated}
              readonly
            />
          </>
        }
      />
      <ValueList
        values={{
          Id: <code>{identity.id}</code>,
          Group: identity.group || <span className="extra">No group</span>,
          Name: identity.name,
          'Created by': admin ? (
            <ResourceIdentifier
              resourceType="user"
              resource={{
                id: identity?.user?.id ?? '',
                displayName: identity?.user?.displayName ?? '',
              }}
              copyable
            />
          ) : undefined,
          'Created at': identity
            ? formatDate(identity.createdAt, config.pageDateFormat)
            : '',
        }}
      />
    </Card>
  );
}

import { faClock } from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Card, EventTypeTag, ResourceIdentifier, TaskBar, Value } from '.';
import config from '../config';
import { Event } from '../types';
import formatDate from '../utilities/format-date';
import './EventCard.scss';

type EventCardProps = {
  event: Event;
  [key: string]: any;
};

export function EventCard({ event, ...props }: EventCardProps): ReactElement {
  return (
    <Card className="event-card" {...props}>
      <TaskBar
        left={
          <>
            <Value
              className="event-card-date"
              icon={faClock}
              value={
                <Link to={`/events/${event.id}`}>
                  {formatDate(event.createdAt, config.pageDateFormat)}
                </Link>
              }
              inline
              standalone
            />
            <Value
              className="event-card-user"
              value={
                <ResourceIdentifier
                  resourceType="user"
                  resource={{
                    id: event?.user?.id ?? '',
                    displayName: event?.user?.displayName ?? '',
                  }}
                  copyable
                />
              }
              inline
              standalone
            />
          </>
        }
        right={
          <Value
            className="event-card-type"
            value={<EventTypeTag eventType={event.type} />}
            inline
            standalone
          />
        }
        background={false}
      />
    </Card>
  );
}

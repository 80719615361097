import { List } from './list-model';
import { Stats } from './stats';

export type IndexValueType = 'string' | 'number' | 'date';

export type IndexDefaultOrderDirection = 'asc' | 'desc';

export type Index = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  list?: List;
  name: string;
  description: string;
  pathName: string;
  pointer: string;
  valueType: IndexValueType;
  alias: boolean;
  sorting: boolean;
  filtering: boolean;
  searching: boolean;
  defaultOrderDirection: IndexDefaultOrderDirection;
  activated: boolean;
  locked?: boolean;
};

export type IndexMinimal = {
  id: string;
  name: string;
  pathName: string;
  listId: string;
};

export type IndexStats = {
  events: Stats<{
    types: {
      [eventType: string]: number;
    };
  }>;
};

export const indexOrderFields = [
  'createdAt',
  'updatedAt',
  'name',
  'pathName',
  'valueType',
  'alias',
  'sorting',
  'filtering',
  'searching',
  'defaultOrderDirection',
  'activated',
] as const;

export type IndexOrderField = (typeof indexOrderFields)[number];

import { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Alert,
  CodeViewer,
  DocumentationTokenSelector,
  JsonViewer,
} from '../../components';
import config from '../../config';
import { useAuth } from '../../hooks/auth';
import { useDocumentationToken } from '../../hooks/documentation-token';
import { Documentation } from '../../layouts/Documentation';
import { DocumentationToken } from '../../types';

export default function DocumentationListSchemas(): ReactElement {
  const { loggedIn } = useAuth();

  const {
    pending: documentationTokensPending,
    tokens: documentationTokens,
    selected: selectedDocumentationToken,
    select: selectDocumentationToken,
  } = useDocumentationToken();

  const sampleId = uuidv4();
  const sampleDate = new Date().toISOString();
  const sampleToken =
    documentationTokens.find(
      (t: DocumentationToken) => t.id === selectedDocumentationToken
    )?.token ?? '<YOUR TOKEN>';

  return (
    <Documentation className="documentation-list-schemas-page">
      <h1>List schemas</h1>
      {loggedIn && documentationTokens.length > 0 && (
        <DocumentationTokenSelector
          tokens={documentationTokens}
          selected={selectedDocumentationToken}
          onChange={selectDocumentationToken}
          disabled={documentationTokensPending}
        />
      )}
      <p>
        By default when you create an item in a list, you can add any JSON data
        you like. However, let's say you want to enforce a specific structure on
        the data that is stored in a list. This is where list schemas come in.
      </p>
      <p>
        You can add a{' '}
        <a href="https://tour.json-schema.org/" target="blank" rel="noreferrer">
          JSON Schema
        </a>{' '}
        when creating or updating a list.
      </p>
      <Alert intent="info" show>
        Note that list schemas are only enforced when creating or updating an
        item, so they won't retroactively apply to existing items in the list.
      </Alert>
      <p>
        If you've got a list containing items and then you add a schema to the
        list, some of those items might not conform to the schema. This is fine,
        but as soon as you add a new item or try to update one of the existing
        items, you'll need to make sure it conforms to the schema.
      </p>
      <p>Here's an example of creating a list with a schema attached:</p>
      <CodeViewer
        srcs={{
          cURL: (
            <>
              <span className="code-keyword">curl</span>&nbsp;
              <span className="code-identifier">{config.api.url}/lists</span>
              &nbsp;<span className="code-punctuation">\</span>
              <br />
              &nbsp;&nbsp;<span className="code-keyword">-H</span>&nbsp;
              <span className="code-string">
                "Content-Type:&nbsp;application/json"
              </span>
              &nbsp;<span className="code-punctuation">\</span>
              <br />
              &nbsp;&nbsp;<span className="code-keyword">-H</span>&nbsp;
              <span className="code-string">
                "x-api-token:&nbsp;{sampleToken}"
              </span>
              &nbsp;<span className="code-punctuation">\</span>
              <br />
              &nbsp;&nbsp;<span className="code-keyword">-d</span>&nbsp;
              <span className="code-string">
                '{'{'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "List
                with a schema",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"description":
                "This list has a JSON schema attached",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"pathName":
                "list-with-schema",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"schema": {'{'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"type":
                "object",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"properties":{' '}
                {'{'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name":{' '}
                {'{'} "type": "string" {'}'},<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"age":{' '}
                {'{'} "type": "number" {'}'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {'}'},<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"required":
                ["name"]
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}'
              </span>
            </>
          ),
          'Node.js': (
            <>
              <span className="code-keyword">import</span>&nbsp;
              <span className="code-identifier">JSONPad</span>
              <span className="code-punctuation">,</span>&nbsp;{'{'}&nbsp;
              <span className="code-identifier">List</span>&nbsp;{'}'}&nbsp;
              <span className="code-keyword">from</span>&nbsp;
              <span className="code-string">'jsonpad'</span>
              <span className="code-punctuation">;</span>
              <br />
              <br />
              <span className="code-keyword">const</span>&nbsp;
              <span className="code-identifier">jsonpad</span>
              <span className="code-punctuation">:</span>&nbsp;
              <span className="code-identifier">JSONPad</span>&nbsp;
              <span className="code-operation">=</span>&nbsp;
              <span className="code-keyword">new</span>&nbsp;
              <span className="code-identifier">JSONPad</span>
              {'('}
              <span className="code-string">'{sampleToken}'</span>
              {')'}
              <span className="code-punctuation">;</span>
              <br />
              <br />
              <span className="code-keyword">const</span>&nbsp;
              <span className="code-identifier">list</span>
              <span className="code-punctuation">:</span>&nbsp;
              <span className="code-identifier">List</span>&nbsp;
              <span className="code-operation">=</span>&nbsp;
              <span className="code-keyword">await</span>&nbsp;
              <span className="code-identifier">jsonpad</span>
              <span className="code-operation">.</span>
              <span className="code-identifier">createList</span>
              {'('}
              {'{'}
              <br />
              &nbsp;&nbsp;name<span className="code-punctuation">:</span>&nbsp;
              <span className="code-string">'List with a schema'</span>
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;description<span className="code-punctuation">:</span>
              &nbsp;
              <span className="code-string">
                'This list has a JSON schema attached'
              </span>
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;pathName<span className="code-punctuation">:</span>
              &nbsp;<span className="code-string">'list-with-schema'</span>
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;schema<span className="code-punctuation">:</span>
              &nbsp;{'{'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;type
              <span className="code-punctuation">:</span>&nbsp;
              <span className="code-string">'object'</span>
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;properties
              <span className="code-punctuation">:</span>&nbsp;{'{'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;name
              <span className="code-punctuation">:</span>&nbsp;{'{'}&nbsp;type
              <span className="code-punctuation">:</span>&nbsp;
              <span className="code-string">'string'</span>&nbsp;{'}'}
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;age
              <span className="code-punctuation">:</span>&nbsp;{'{'}&nbsp;type
              <span className="code-punctuation">:</span>&nbsp;
              <span className="code-string">'number'</span>&nbsp;{'}'}
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;required
              <span className="code-punctuation">:</span>&nbsp;[
              <span className="code-string">'name'</span>]
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;{'}'}
              <span className="code-punctuation">,</span>
              <br />
              {'}'}
              {')'}
              <span className="code-punctuation">;</span>
            </>
          ),
        }}
        border
      />
      <p>Now if we try to create an item in the list:</p>
      <CodeViewer
        srcs={{
          cURL: (
            <>
              <span className="code-keyword">curl</span>&nbsp;
              <span className="code-identifier">
                {config.api.url}/lists/list-with-schema/items
              </span>
              &nbsp;<span className="code-punctuation">\</span>
              <br />
              &nbsp;&nbsp;<span className="code-keyword">-H</span>&nbsp;
              <span className="code-string">
                "Content-Type:&nbsp;application/json"
              </span>
              &nbsp;<span className="code-punctuation">\</span>
              <br />
              &nbsp;&nbsp;<span className="code-keyword">-H</span>&nbsp;
              <span className="code-string">
                "x-api-token:&nbsp;{sampleToken}"
              </span>
              &nbsp;<span className="code-punctuation">\</span>
              <br />
              &nbsp;&nbsp;<span className="code-keyword">-d</span>&nbsp;
              <span className="code-string">
                '{'{'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"data": {'{'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name":
                "John Doe",
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"age":
                30
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}'
              </span>
            </>
          ),
          'Node.js': (
            <>
              <span className="code-keyword">import</span>&nbsp;
              <span className="code-identifier">JSONPad</span>
              <span className="code-punctuation">,</span>&nbsp;{'{'}&nbsp;
              <span className="code-identifier">Item</span>&nbsp;{'}'}&nbsp;
              <span className="code-keyword">from</span>&nbsp;
              <span className="code-string">'jsonpad'</span>
              <span className="code-punctuation">;</span>
              <br />
              <br />
              <span className="code-keyword">const</span>&nbsp;
              <span className="code-identifier">jsonpad</span>
              <span className="code-punctuation">:</span>&nbsp;
              <span className="code-identifier">JSONPad</span>&nbsp;
              <span className="code-operation">=</span>&nbsp;
              <span className="code-keyword">new</span>&nbsp;
              <span className="code-identifier">JSONPad</span>
              {'('}
              <span className="code-string">'{sampleToken}'</span>
              {')'}
              <span className="code-punctuation">;</span>
              <br />
              <br />
              <span className="code-keyword">const</span>&nbsp;
              <span className="code-identifier">item</span>
              <span className="code-punctuation">:</span>&nbsp;
              <span className="code-identifier">Item</span>&nbsp;
              <span className="code-operation">=</span>&nbsp;
              <span className="code-keyword">await</span>&nbsp;
              <span className="code-identifier">jsonpad</span>
              <span className="code-operation">.</span>
              <span className="code-identifier">createItem</span>
              {'('}
              <br />
              &nbsp;&nbsp;
              <span className="code-string">'list-with-schema'</span>
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;{'{'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;data
              <span className="code-punctuation">:</span>&nbsp;{'{'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;name
              <span className="code-punctuation">:</span>&nbsp;
              <span className="code-string">'John Doe'</span>
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;age
              <span className="code-punctuation">:</span>&nbsp;
              <span className="code-number">30</span>
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;{'}'}
              <br />
              {')'}
              <span className="code-punctuation">;</span>
            </>
          ),
        }}
        border
      />
      <JsonViewer
        src={{
          id: sampleId,
          createdAt: sampleDate,
          updatedAt: sampleDate,
          data: {
            age: 30,
            name: 'John Doe',
          },
          version: '1',
          readonly: false,
          activated: false,
          description: '',
          size: 28,
          locked: false,
        }}
      />
      <br />
      <p>
        This worked! The item conforms to the schema we set on the list. Let's
        try another one:
      </p>
      <CodeViewer
        srcs={{
          cURL: (
            <>
              <span className="code-keyword">curl</span>&nbsp;
              <span className="code-identifier">
                {config.api.url}/lists/list-with-schema/items
              </span>
              &nbsp;<span className="code-punctuation">\</span>
              <br />
              &nbsp;&nbsp;<span className="code-keyword">-H</span>&nbsp;
              <span className="code-string">
                "Content-Type:&nbsp;application/json"
              </span>
              &nbsp;<span className="code-punctuation">\</span>
              <br />
              &nbsp;&nbsp;<span className="code-keyword">-H</span>&nbsp;
              <span className="code-string">
                "x-api-token:&nbsp;{sampleToken}"
              </span>
              &nbsp;<span className="code-punctuation">\</span>
              <br />
              &nbsp;&nbsp;<span className="code-keyword">-d</span>&nbsp;
              <span className="code-string">
                '{'{'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"data": {'{'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"age":
                30
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}'
              </span>
            </>
          ),
          'Node.js': (
            <>
              <span className="code-keyword">import</span>&nbsp;
              <span className="code-identifier">JSONPad</span>
              <span className="code-punctuation">,</span>&nbsp;{'{'}&nbsp;
              <span className="code-identifier">Item</span>&nbsp;{'}'}&nbsp;
              <span className="code-keyword">from</span>&nbsp;
              <span className="code-string">'jsonpad'</span>
              <span className="code-punctuation">;</span>
              <br />
              <br />
              <span className="code-keyword">const</span>&nbsp;
              <span className="code-identifier">jsonpad</span>
              <span className="code-punctuation">:</span>&nbsp;
              <span className="code-identifier">JSONPad</span>&nbsp;
              <span className="code-operation">=</span>&nbsp;
              <span className="code-keyword">new</span>&nbsp;
              <span className="code-identifier">JSONPad</span>
              {'('}
              <span className="code-string">'{sampleToken}'</span>
              {')'}
              <span className="code-punctuation">;</span>
              <br />
              <br />
              <span className="code-keyword">const</span>&nbsp;
              <span className="code-identifier">item</span>
              <span className="code-punctuation">:</span>&nbsp;
              <span className="code-identifier">Item</span>&nbsp;
              <span className="code-operation">=</span>&nbsp;
              <span className="code-keyword">await</span>&nbsp;
              <span className="code-identifier">jsonpad</span>
              <span className="code-operation">.</span>
              <span className="code-identifier">createItem</span>
              {'('}
              <br />
              &nbsp;&nbsp;
              <span className="code-string">'list-with-schema'</span>
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;{'{'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;data
              <span className="code-punctuation">:</span>&nbsp;{'{'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;age
              <span className="code-punctuation">:</span>&nbsp;
              <span className="code-number">30</span>
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
              <span className="code-punctuation">,</span>
              <br />
              &nbsp;&nbsp;{'}'}
              <br />
              {')'}
              <span className="code-punctuation">;</span>
            </>
          ),
        }}
        border
      />
      <JsonViewer
        src={{
          name: 'VALIDATION_ERROR',
          code: 10003,
          message: 'Validation error (requires property \\"name\\")',
        }}
      />
      <br />
      <p>
        This time we received a <code>400 Bad Request</code> response because
        the item doesn't conform to the schema.
      </p>
      <p>The response contains further information about what went wrong.</p>
      <p>
        Schemas can be very useful when combined with{' '}
        <a href="/docs/item-indexing">Indexes</a> since we can make sure that
        every item in a list contains specific fields, and then we can query the
        items based on that structure.
      </p>
      <span className="documentation-last-updated">2024-11-12</span>
    </Documentation>
  );
}

import { Stats } from './stats';

export type User = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  displayName: string;
  description: string;
  firebaseUid?: string;
  lastActiveAt?: Date;
  admin: boolean;
  activated: boolean;
};

export type UserMinimal = {
  id: string;
  displayName: string;
};

export type UserStats = {
  events: Stats<{
    types: {
      [eventType: string]: number;
    };
  }>;
};

export const userOrderFields = [
  'createdAt',
  'updatedAt',
  'lastActiveAt',
  'firebaseUid',
  'displayName',
  'admin',
  'activated',
] as const;

export type UserOrderField = (typeof userOrderFields)[number];

import {
  faFileCode,
  faLock,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import prettyBytes from 'pretty-bytes';
import { ReactElement, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Switch, Tag, TaskBar, ValueList } from '.';
import config from '../config';
import theme from '../exports.module.scss';
import { Item, List } from '../types';
import classNames from '../utilities/class-names';
import formatDate from '../utilities/format-date';
import './ItemCard.scss';

type ItemCardProps = {
  list: List;
  item: Item;
  handleShowSubscriptionLockedModal?: () => void;
  [key: string]: any;
};

export function ItemCard({
  list,
  item,
  handleShowSubscriptionLockedModal,
  ...props
}: ItemCardProps): ReactElement {
  const [subscriptionLockedButtonShake, setSubscriptionLockedButtonShake] =
    useState(true);

  const handleSubscriptionLockedTagClick = useCallback(() => {
    handleShowSubscriptionLockedModal?.();

    setSubscriptionLockedButtonShake(false);
    setTimeout(() => {
      setSubscriptionLockedButtonShake(true);
    }, 500);
  }, [handleShowSubscriptionLockedModal]);

  return (
    <Card
      className={classNames('item-card', item.locked ? 'item-locked' : '')}
      {...props}
    >
      <div className="corner-tags-container bottom">
        <Tag
          intent="warning"
          icon={faLock}
          show={item.locked}
          onClick={handleSubscriptionLockedTagClick}
          shake={subscriptionLockedButtonShake}
        >
          Locked
        </Tag>
      </div>
      <TaskBar
        left={
          <h2>
            <FontAwesomeIcon icon={faFileCode} />
            <Link to={`/lists/${list.id}/items/${item.id}`}>{item.id}</Link>
          </h2>
        }
        right={
          <>
            <Switch
              name={`item-${item.id}-readonly`}
              label="Readonly"
              icon={faLock}
              color={theme.intentWarning}
              value={item.readonly}
              readonly
            />
            <Switch
              name={`item-${item.id}-activated`}
              label="Activated"
              icon={faPowerOff}
              color={theme.intentSuccess}
              value={item.activated}
              readonly
            />
          </>
        }
      />
      <ValueList
        values={{
          Id: <code>{item.id}</code>,
          Created: item
            ? formatDate(item.createdAt, config.pageDateFormat)
            : '',
          Size: prettyBytes(item.size),
        }}
      />
    </Card>
  );
}

/**
 * Convert an rgba object to a CSS colour string
 */
export default function rgbaToColour(rgba: {
  r: number;
  g: number;
  b: number;
  a?: number;
}): string {
  const { r, g, b, a = 1 } = rgba;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

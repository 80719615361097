import { range } from '@basementuniverse/utils';
import {
  faClipboard,
  faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SingleValue } from 'react-select';
import classNames from '../utilities/class-names';
import './CodeViewer.scss';
import { Select, SelectOptionProps } from './Select';

const COPY_TO_CLIPBOARD_RESET_DELAY = 3000;

type CodeViewerProps = {
  className?: string;
  copyable?: boolean;
  srcs: Record<string, ReactNode>;
  border?: boolean;
  lineNumbers?: boolean;
  [key: string]: any;
};

export function CodeViewer({
  className,
  copyable = true,
  srcs,
  border = false,
  lineNumbers = true,
  ...props
}: CodeViewerProps): ReactElement {
  const codeRef = useRef<HTMLElement>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    Object.keys(srcs || {})[0] ?? ''
  );
  const [text, setText] = useState<string>('');
  const [lineCount, setLineCount] = useState<number>(0);
  const [textCopiedToClipboard, setTextCopiedToClipboard] = useState(false);

  const languageOptions: SelectOptionProps<string>[] = useMemo(
    () =>
      Object.keys(srcs).map(language => ({
        label: language,
        value: language,
      })),
    [srcs]
  );

  const handleChangeSelectedLanguage = useCallback(
    async (selectedLanguage: SingleValue<SelectOptionProps<string>>) => {
      if (selectedLanguage) {
        setSelectedLanguage(selectedLanguage.value);
      }
    },
    [setSelectedLanguage]
  );

  useEffect(() => {
    if (!codeRef.current) {
      return;
    }

    const text = codeRef.current.innerText;

    setText(text);
    setLineCount(text.split('\n').length);
  }, [selectedLanguage]);

  useEffect(() => {
    if (textCopiedToClipboard) {
      setTimeout(() => {
        setTextCopiedToClipboard(false);
      }, COPY_TO_CLIPBOARD_RESET_DELAY);
    }
  }, [textCopiedToClipboard]);

  return (
    <div
      className={classNames(
        'code-viewer',
        className,
        border ? 'border' : undefined,
        lineNumbers ? 'line-numbers' : undefined
      )}
      {...props}
    >
      <div className="code-viewer-header">
        <Select
          name="language-select"
          className="code-viewer-language-select"
          options={languageOptions}
          defaultValue={languageOptions.find(
            option => option.value === selectedLanguage
          )}
          onChange={handleChangeSelectedLanguage as any}
          disabled={languageOptions.length <= 1}
        />
        {copyable && (
          <CopyToClipboard
            text={text}
            onCopy={() => {
              setTextCopiedToClipboard(true);
            }}
          >
            <button
              type="button"
              className="code-viewer-copy-to-clipboard-button"
            >
              <FontAwesomeIcon
                icon={textCopiedToClipboard ? faClipboardCheck : faClipboard}
              />
            </button>
          </CopyToClipboard>
        )}
      </div>
      {lineNumbers && (
        <span className="code-viewer-line-numbers">
          {range(lineCount).map(i => (
            <span key={i}>{i + 1}</span>
          ))}
        </span>
      )}
      <code className="code-viewer-code" ref={codeRef}>
        {srcs[selectedLanguage]}
      </code>
    </div>
  );
}

import { pluck } from '@basementuniverse/utils';

/**
 * Plucks the specified keys from an object, but only includes defined values
 */
export default function pluckParams<T extends object, K extends keyof T>(
  o: T,
  ...keys: K[]
): Record<string, string> {
  return Object.fromEntries(
    Object.entries(pluck<T, K>(o, ...keys))
      .filter(([, value]) => value !== undefined && value !== null)
      .map(([key, value]) => [key, (value as any).toString()])
  );
}

import {
  faArrowUpShortWide,
  faFilter,
  faLock,
  faMagnifyingGlass,
  faPowerOff,
  faTag,
  faTags,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Switch, Tag, TaskBar, ValueList } from '.';
import config from '../config';
import theme from '../exports.module.scss';
import { Index, List } from '../types';
import classNames from '../utilities/class-names';
import formatDate from '../utilities/format-date';
import './IndexCard.scss';
import { IndexValueTypeTag } from './IndexValueTypeTag';

type IndexCardProps = {
  list: List;
  index: Index;
  handleShowSubscriptionLockedModal?: () => void;
  [key: string]: any;
};

export function IndexCard({
  list,
  index,
  handleShowSubscriptionLockedModal,
  ...props
}: IndexCardProps): ReactElement {
  const [subscriptionLockedButtonShake, setSubscriptionLockedButtonShake] =
    useState(true);

  const handleSubscriptionLockedTagClick = useCallback(() => {
    handleShowSubscriptionLockedModal?.();

    setSubscriptionLockedButtonShake(false);
    setTimeout(() => {
      setSubscriptionLockedButtonShake(true);
    }, 500);
  }, [handleShowSubscriptionLockedModal]);

  return (
    <Card
      className={classNames('index-card', index.locked ? 'index-locked' : '')}
      {...props}
    >
      <div className="corner-tags-container bottom">
        <Tag
          intent="warning"
          icon={faLock}
          show={index.locked}
          onClick={handleSubscriptionLockedTagClick}
          shake={subscriptionLockedButtonShake}
        >
          Locked
        </Tag>
      </div>
      <TaskBar
        left={
          <h2>
            <FontAwesomeIcon icon={faTags} />
            <Link to={`/lists/${list.id}/indexes/${index.id}`}>
              {index.name || 'Unnamed index'}
            </Link>
            {index.pathName && <span className="extra">{index.pathName}</span>}
          </h2>
        }
        right={
          <>
            <Switch
              name={`index-${index.id}-alias`}
              label="Alias"
              icon={faTag}
              color={theme.intentInfo}
              value={index.alias}
              readonly
            />
            <Switch
              name={`index-${index.id}-sorting`}
              label="Sorting"
              icon={faArrowUpShortWide}
              color={theme.intentWarning}
              value={index.sorting}
              readonly
            />
            <Switch
              name={`index-${index.id}-filtering`}
              label="Filtering"
              icon={faFilter}
              color={theme.intentWarning}
              value={index.filtering}
              readonly
            />
            <Switch
              name={`index-${index.id}-searching`}
              label="Searching"
              icon={faMagnifyingGlass}
              color={theme.intentWarning}
              value={index.searching}
              readonly
            />
            <Switch
              name={`index-${index.id}-activated`}
              label="Activated"
              icon={faPowerOff}
              color={theme.intentSuccess}
              value={index.activated}
              readonly
            />
          </>
        }
      />
      <ValueList
        values={{
          Id: <code>{index.id}</code>,
          Created: index
            ? formatDate(index.createdAt, config.pageDateFormat)
            : '',
          Pointer: <code>{index.pointer}</code>,
          'Value type': <IndexValueTypeTag valueType={index.valueType} />,
        }}
      />
    </Card>
  );
}

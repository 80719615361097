import { ReactElement, useCallback, useEffect, useState } from 'react';
import classNames from '../utilities/class-names';
import './Checkbox.scss';

type CheckboxProps = {
  name?: string;
  label?: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  [key: string]: any;
};

export function Checkbox({
  name,
  label,
  value,
  disabled,
  onChange,
  ...props
}: CheckboxProps): ReactElement {
  const [checked, setChecked] = useState(!!value);

  useEffect(() => {
    setChecked(!!value);
  }, [value]);

  const handleChange = useCallback(
    (currentValue: boolean) => {
      setChecked(currentValue);
      onChange?.(currentValue);
    },
    [onChange]
  );

  return (
    <label
      className={classNames(
        'checkbox',
        disabled ? 'checkbox-disabled' : '',
        label ? 'checkbox-has-label' : 'checkbox-no-label'
      )}
    >
      <input
        type="checkbox"
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={e => handleChange(e.target.checked)}
        {...props}
      />
      {label && <span className="checkbox-label">{label ?? ''}</span>}
    </label>
  );
}

import {
  AuthError as FirebaseAuthError,
  User as FirebaseUser,
  GithubAuthProvider,
  GoogleAuthProvider,
  UserCredential,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../config';
import { useLocalStorage } from '../hooks/local-storage';
import {
  Error as ApiError,
  AuthContextState,
  User as DatabaseUser,
} from '../types';
import firebase from '../utilities/firebase';

export const AuthContext = createContext<AuthContextState | undefined>(
  undefined
);

type AuthProviderProps = {
  children?: ReactNode;
};

const LOGOUT_REDIRECT_DELAY = 1000;
const TOKEN_EXPIRY = 1000 * 60 * 60 - 1000 * 30; // 30 seconds less than 1 hour

export function AuthProvider({ children }: AuthProviderProps): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth(firebase);
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null);
  const [databaseUser, setDatabaseUser] = useState<DatabaseUser | null>(null);
  const [error, setError] = useState<Error | FirebaseAuthError | null>(null);
  const [pending, setPending] = useState(true);
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [cachedToken, setCachedToken, clearCachedToken] = useLocalStorage(
    'auth-token',
    ''
  );
  const [tokenExpiresAt, setTokenExpiresAt] = useLocalStorage(
    'auth-token-expires-at',
    ''
  );

  const register = useCallback(
    async (
      email: string,
      password: string,
      displayName: string
    ): Promise<DatabaseUser | void> => {
      setPending(true);

      return fetch(`${config.api.url}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, displayName }),
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as DatabaseUser;
        })
        .catch((error: Error) => {
          setPending(false);
          setError(error);
        });
    },
    []
  );

  const forgotPassword = useCallback(async (email: string): Promise<void> => {
    setPending(true);

    fetch(`${config.api.url}/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then(async response => {
        setPending(false);
        setError(null);

        if (!response.ok) {
          setError(new Error(((await response.json()) as ApiError).message));
        }

        return;
      })
      .catch((error: Error) => {
        setPending(false);
        setError(error);
      });
  }, []);

  const changePassword = useCallback(async (): Promise<void> => {
    setPending(true);

    fetch(`${config.api.url}/change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async response => {
        setPending(false);
        setError(null);

        if (!response.ok) {
          setError(new Error(((await response.json()) as ApiError).message));
        }

        return;
      })
      .catch((error: Error) => {
        setPending(false);
        setError(error);
      });
  }, []);

  const loginWithEmailAndPassword = useCallback(
    async (email: string, password: string): Promise<boolean> => {
      setPending(true);

      let result: UserCredential;

      try {
        result = await signInWithEmailAndPassword(auth, email, password);
      } catch (error: unknown) {
        setPending(false);
        setError(error as FirebaseAuthError);
        clearCachedToken();

        return false;
      }

      setPending(false);
      setError(null);
      setCachedToken((await result.user.getIdToken(true)) ?? '');

      return true;
    },
    [auth, clearCachedToken, setCachedToken]
  );

  const loginWithGoogle = useCallback(async (): Promise<boolean> => {
    setPending(true);

    const provider = new GoogleAuthProvider();
    let result: UserCredential;

    try {
      result = await signInWithPopup(auth, provider);
    } catch (error: unknown) {
      setPending(false);
      setError(error as FirebaseAuthError);
      clearCachedToken();

      return false;
    }

    setPending(false);
    setError(null);
    setCachedToken(await result.user.getIdToken(true));

    return true;
  }, [auth, clearCachedToken, setCachedToken]);

  const loginWithGithub = useCallback(async (): Promise<boolean> => {
    setPending(true);

    const provider = new GithubAuthProvider();
    let result: UserCredential;

    try {
      result = await signInWithPopup(auth, provider);
    } catch (error: unknown) {
      setPending(false);
      setError(error as FirebaseAuthError);
      clearCachedToken();

      return false;
    }

    setPending(false);
    setError(null);
    setCachedToken(await result.user.getIdToken(true));

    return true;
  }, [auth, clearCachedToken, setCachedToken]);

  const logout = useCallback(async (): Promise<void> => {
    setPending(true);

    signOut(auth);

    setDatabaseUser(null);
    setFirebaseUser(null);
    setLoggedIn(true);
    setPending(false);
    setCachedToken('');
    setTokenExpiresAt('');

    // Wait a bit then redirect user back to login page
    setTimeout(() => {
      navigate('/login');
    }, LOGOUT_REDIRECT_DELAY);
  }, [auth, setCachedToken, setTokenExpiresAt, navigate]);

  useEffect(() => {
    setPending(true);

    // Allow auth bypass in local environment
    if (
      config.env === 'local' &&
      config.debug.bypassAuth &&
      config.debug.overrideUserId
    ) {
      fetch(`${config.api.url}/users/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-override-user-id': config.debug.overrideUserId,
        },
      })
        .then(async response => {
          const databaseUserData = (await response.json()) as DatabaseUser;
          setDatabaseUser(databaseUserData);
          setFirebaseUser(null);
          setLoggedIn(true);
          setPending(false);
          setCachedToken('');
        })
        .catch((error: Error) => {
          setPending(false);
          setError(error);
        });
      return;
    }

    // Handle auth state changed (a user has logged in)
    const unlisten = onAuthStateChanged(auth, async firebaseUserData => {
      const token = await firebaseUserData?.getIdToken();

      if (!token) {
        setLoggedIn(false);
        setPending(false);
        setCachedToken('');
        setTokenExpiresAt('');
        return;
      }

      if (token !== cachedToken || Date.now() >= Number(tokenExpiresAt)) {
        setCachedToken(token);
        setTokenExpiresAt(String(Date.now() + TOKEN_EXPIRY));
      }

      fetch(`${config.api.url}/users/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token ?? '',
        },
      })
        .then(async response => {
          const databaseUserData = (await response.json()) as DatabaseUser;
          setDatabaseUser(databaseUserData);
          setFirebaseUser(firebaseUserData);
          setLoggedIn(!!firebaseUserData && !!databaseUserData);
          setPending(false);
          setCachedToken(token ?? '');
          setTokenExpiresAt(String(Date.now() + TOKEN_EXPIRY));
        })
        .catch((error: Error) => {
          setPending(false);
          setError(error);
        });
    });

    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, cachedToken, setCachedToken]);

  // On every page change, we check if the token is expiring soon and refresh
  // the cache if it is
  useEffect(() => {
    if (!firebaseUser) {
      return;
    }

    const refreshCachedToken = async () => {
      const token = await firebaseUser?.getIdToken();
      setCachedToken(token);
    };

    if (Date.now() >= Number(tokenExpiresAt)) {
      refreshCachedToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return (
    <AuthContext.Provider
      value={{
        pending,
        loggedIn,
        firebaseUser,
        databaseUser,
        error,
        token: cachedToken,
        register,
        forgotPassword,
        changePassword,
        loginWithEmailAndPassword,
        loginWithGoogle,
        loginWithGithub,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import config from '../config';
import JsonPadLogoSimple from '../images/jsonpad-logo-simple.svg';
import JsonPadLogo from '../images/jsonpad-logo.svg';
import './Title.scss';

type TitleProps = {
  simple?: boolean;
  titleLink?: string;
};

export function Title({
  simple = false,
  titleLink = '/',
}: TitleProps): ReactElement {
  return (
    <div className="title">
      <Link to={titleLink}>
        <img
          src={simple ? JsonPadLogoSimple : JsonPadLogo}
          className="title-logo"
          alt={config.name}
        />
      </Link>
    </div>
  );
}

export const information = {
  copyrightYear: '2024',
  companyNumber: '12613006',
  address: ['Fl3x Ltd', '20-22 Wenlock Road', 'London', 'N1 7GU'],
  emailAddress: 'contact@jsonpad.io',
  emailSubject: 'Website enquiry',
  xLink: 'https://x.com/jsonpadio',
  githubLink: 'https://github.com/basementuniverse/jsonpad.io',
  resourcesLinks: [
    {
      text: 'Terms & conditions',
      url: '/terms',
    },
    {
      text: 'Privacy policy',
      url: '/privacy',
    },
    {
      text: 'Branding',
      url: 'https://jsonpad.io/jsonpad-branding.zip',
    },
    {
      text: 'Report issues',
      url: 'https://github.com/basementuniverse/jsonpad.io/issues',
    },
    {
      text: 'Javascript SDK',
      url: 'https://www.npmjs.com/package/@basementuniverse/jsonpad-sdk',
    },
    {
      text: 'Realtime SDK',
      url: 'https://www.npmjs.com/package/@basementuniverse/jsonpad-realtime-sdk',
    },
    {
      text: 'API status',
      url: 'https://stats.uptimerobot.com/6qT7rAbk4j',
    },
  ],
};

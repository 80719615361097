import {
  IconDefinition,
  faChartLine,
  faChartSimple,
  faFileCode,
  faFolder,
  faPassport,
  faTags,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Tag } from '.';
import { PermissionResourceType } from '../types';
import classNames from '../utilities/class-names';

type TokenPermissionResourceTypeTagStyle = {
  icon: IconDefinition;
  className: string;
  label: string;
};

const tokenPermissionResourceTypeTagStyles: {
  [key in PermissionResourceType]: TokenPermissionResourceTypeTagStyle;
} = {
  list: {
    icon: faFolder,
    className: 'token-permission-resource-type-tag-list',
    label: 'List',
  },
  item: {
    icon: faFileCode,
    className: 'token-permission-resource-type-tag-item',
    label: 'Item',
  },
  index: {
    icon: faTags,
    className: 'token-permission-resource-type-tag-index',
    label: 'Index',
  },
  identity: {
    icon: faPassport,
    className: 'token-permission-resource-type-tag-identity',
    label: 'Identity',
  },
  event: {
    icon: faChartSimple,
    className: 'token-permission-resource-type-tag-event',
    label: 'Events',
  },
  stats: {
    icon: faChartLine,
    className: 'token-permission-resource-type-tag-stats',
    label: 'Stats',
  },
};

type TokenPermissionResourceTypeTagProps = {
  resourceType: PermissionResourceType;
};

export function TokenPermissionResourceTypeTag({
  resourceType,
}: TokenPermissionResourceTypeTagProps): ReactElement {
  let { icon, className, label } =
    tokenPermissionResourceTypeTagStyles[resourceType];

  return (
    <Tag
      show
      className={classNames('token-permission-resource-type-tag', className)}
      icon={icon}
    >
      {label}
    </Tag>
  );
}

import Delaunator from 'delaunator';
import Hammer from 'hammerjs';

export const liquid = (canvas: HTMLCanvasElement, gridSize?: number): void => {
  const X = 0;
  const Y = 1;
  const Z = 2;
  const VX = 3;
  const VY = 4;
  const VZ = 5;
  const R = 0;
  const G = 1;
  const B = 2;
  const GRID_SIZE = 10;
  const GRID_BUFFER = 2;
  const MIN_CELL_SIZE = 50;
  const RANDOMIZATION = 0.8;
  const Z_OFFSET = 10;
  const LIGHT_DIRECTION = [1, 1, 0];
  const BASE_COLOUR = [49, 54, 63];
  const SHADOW_COLOUR = [34, 40, 49];
  const LIGHT_COLOUR = [118, 171, 174];
  const SPREAD = 0.3;
  const DAMPING = 0.02;
  const FRICTION = 0.999;
  const WAVE_COUNT = 20;
  const WAVE_AMOUNT = 0.3;
  const FREQUENCY = 2;
  const PHASE = 5;
  const FADEIN_TIME = 2;
  const MOUSE_AMOUNT = 0.7;

  const actualGridSize = gridSize || GRID_SIZE;

  // Get a 2d context for the canvas
  const context = canvas.getContext('2d');
  if (!context) {
    throw new Error('Unable to get context');
  }

  let width = 0;
  let height = 0;
  let cellSize = 0;
  let points: number[][] = [];
  let triangles: Uint32Array = new Uint32Array();
  let elapsedTime = 0;

  // Handle mouse input
  const hammer = new Hammer.Manager(canvas, {
    recognizers: [[Hammer.Pan]],
  });
  const mouse = {
    position: [0, 0],
    down: false,
  };
  hammer.on('panstart', () => {
    mouse.down = true;
  });
  hammer.on('panend', () => {
    mouse.down = false;
  });
  hammer.on('pan', e => {
    const canvasBoundingRect = canvas.getBoundingClientRect();
    mouse.position[X] = e.center.x - canvasBoundingRect.x;
    mouse.position[Y] = e.center.y - canvasBoundingRect.y;
  });

  // Handle window resize
  function resize(): void {
    canvas.width = width = canvas.clientWidth;
    canvas.height = height = canvas.clientHeight;
    draw(context!);
  }
  window.onresize = resize;
  resize();

  // Wave generator
  type Wave = {
    frequency: number;
    phase: number;
  };

  class WaveGenerator {
    public n: number;
    public wavesX: Wave[];
    public wavesY: Wave[];

    public constructor(n: number) {
      this.n = n;
      this.wavesX = [];
      this.wavesY = [];
      for (let i = 0; i < n; i++) {
        this.wavesX.push({
          frequency: (Math.random() * 2 - 1) * FREQUENCY,
          phase: (Math.random() * 2 - 1) * PHASE,
        });
        this.wavesY.push({
          frequency: (Math.random() * 2 - 1) * FREQUENCY,
          phase: (Math.random() * 2 - 1) * PHASE,
        });
      }
    }

    public sample(t: number, x: number, y: number): number {
      let result = 0,
        a = 1 / this.n;
      for (let i = 0; i < this.n; i++) {
        result +=
          (Math.sin(t * this.wavesX[i].frequency + x + this.wavesX[i].phase) +
            Math.cos(t * this.wavesY[i].frequency + y + this.wavesY[i].phase)) *
          a;
      }
      return result * WAVE_AMOUNT;
    }
  }
  const waveGenerator = new WaveGenerator(WAVE_COUNT);

  // Initialise the simulation
  function initialise(): void {
    // Create a grid of randomly offset centroids
    const size = actualGridSize + GRID_BUFFER * 2;
    let offsetX, offsetY;
    for (let y = 0; y < size; y++) {
      for (let x = 0; x < size; x++) {
        offsetX = (Math.random() - 0.5) * RANDOMIZATION;
        offsetY = (Math.random() - 0.5) * RANDOMIZATION;
        points.push([x + 0.5 + offsetX, y + 0.5 + offsetY]);
      }
    }

    // Generate delaunay triangulation for these points
    const d = new Delaunator(points.flat());
    triangles = d.triangles;

    // Give each point a z offset and a velocity
    for (let point of points) {
      point.push(Z_OFFSET, 0, 0, 0);
    }
  }

  // Handle user input
  function handleInput(): void {
    if (mouse.down) {
      const gx = Math.floor(mouse.position[X] / cellSize) + GRID_BUFFER;
      const gy = Math.floor(mouse.position[Y] / cellSize) + GRID_BUFFER;
      const p = points[index(gx, gy)];

      if (!p) {
        return;
      }

      const m = [
        mouse.position[X] / cellSize + GRID_BUFFER,
        mouse.position[Y] / cellSize + GRID_BUFFER,
      ];
      const d = clamp(1 - len(sub([m[X], m[Y], 0], [p[X], p[Y], 0])), 0, 1);
      p[Z] += MOUSE_AMOUNT * d;
    }
  }

  // Update the simulation
  function update(dt: number): void {
    elapsedTime += dt;
    const size = actualGridSize + GRID_BUFFER * 2;
    let point, d, a;
    for (let y = 0; y < size; y++) {
      for (let x = 0; x < size; x++) {
        point = points[index(x, y)];

        // Waves
        point[Z] += waveGenerator.sample(elapsedTime, x, y);

        // Spread
        a = (averageAdjacent(x, y) - point[Z]) * SPREAD;
        point[Z] += a;

        // Spring
        d = mul(sub([point[X], point[Y], Z_OFFSET], point), DAMPING);
        point[VX] += d[X];
        point[VY] += d[Y];
        point[VZ] += d[Z];
        point[VX] *= FRICTION;
        point[VY] *= FRICTION;
        point[VZ] *= FRICTION;
        point[X] += point[VX];
        point[Y] += point[VY];
        point[Z] += point[VZ];
      }
    }
  }

  // Render the simulation
  function draw(context: CanvasRenderingContext2D): void {
    context.save();
    context.globalAlpha = clamp(elapsedTime / FADEIN_TIME, 0, 1);
    context.fillStyle = rgb(BASE_COLOUR[R], BASE_COLOUR[G], BASE_COLOUR[B]);
    context.fillRect(0, 0, width, height);

    // Scale and translate the canvas
    cellSize = Math.max(
      Math.max(width, height) / actualGridSize,
      MIN_CELL_SIZE
    );
    context.scale(cellSize, cellSize);
    context.translate(-GRID_BUFFER, -GRID_BUFFER);

    // Render each triangle
    let p1, p2, p3, d, colour;
    for (let i = 0; i < triangles.length; i += 3) {
      // Transform the triangle vertices using a perspective transform
      p1 = project(points[triangles[i]]);
      p2 = project(points[triangles[i + 1]]);
      p3 = project(points[triangles[i + 2]]);

      // Calculate dot product of light direction and surface normal
      d = dot(
        norm(LIGHT_DIRECTION),
        norm(
          cross(
            sub(points[triangles[i + 1]], points[triangles[i]]),
            sub(points[triangles[i + 2]], points[triangles[i]])
          )
        )
      );

      // Blend shadow / light / base colours to get the surface colour
      if (d < 0) {
        colour = rgb(
          lerp(BASE_COLOUR[R], SHADOW_COLOUR[R], Math.abs(d)),
          lerp(BASE_COLOUR[G], SHADOW_COLOUR[G], Math.abs(d)),
          lerp(BASE_COLOUR[B], SHADOW_COLOUR[B], Math.abs(d))
        );
      } else {
        colour = rgb(
          lerp(BASE_COLOUR[R], LIGHT_COLOUR[R], d),
          lerp(BASE_COLOUR[G], LIGHT_COLOUR[G], d),
          lerp(BASE_COLOUR[B], LIGHT_COLOUR[B], d)
        );
      }

      // Render the triangle
      drawTriangle(context, p1, p2, p3, colour);
    }

    // context.fillStyle = 'white';
    // for (let i = 0; i < points.length; i++) {
    //     context.fillRect(points[i][X], points[i][Y], 1 / cellSize, 1 / cellSize);
    // }

    context.restore();
  }

  // Render a triangle
  function drawTriangle(
    context: CanvasRenderingContext2D,
    p1: number[],
    p2: number[],
    p3: number[],
    colour: string
  ): void {
    context.fillStyle = colour;
    context.beginPath();
    context.moveTo(p1[X], p1[Y]);
    context.lineTo(p2[X], p2[Y]);
    context.lineTo(p3[X], p3[Y]);
    context.closePath();
    context.fill();
  }

  // Transform a 3d point onto a 2d plane using a perspective projection
  function project(v: number[]): number[] {
    const size = Math.floor(actualGridSize / 2 + GRID_BUFFER);
    const x = v[X] - size;
    const y = v[Y] - size;
    const r = Z_OFFSET / v[Z];
    return [r * x + size, r * y + size];
  }

  // Get average z position of adjacent points
  function averageAdjacent(x: number, y: number): number {
    const tl = (points[index(x - 1, y - 1)] || [0, 0, Z_OFFSET])[Z];
    const t = (points[index(x, y - 1)] || [0, 0, Z_OFFSET])[Z];
    const tr = (points[index(x + 1, y - 1)] || [0, 0, Z_OFFSET])[Z];
    const l = (points[index(x - 1, y)] || [0, 0, Z_OFFSET])[Z];
    const r = (points[index(x + 1, y)] || [0, 0, Z_OFFSET])[Z];
    const bl = (points[index(x - 1, y + 1)] || [0, 0, Z_OFFSET])[Z];
    const b = (points[index(x, y + 1)] || [0, 0, Z_OFFSET])[Z];
    const br = (points[index(x + 1, y + 1)] || [0, 0, Z_OFFSET])[Z];
    return (tl + t + tr + l + r + bl + b + br) / 8;
  }

  // Get an array index from a 2d position
  function index(x: number, y: number): number {
    return y * (actualGridSize + GRID_BUFFER * 2) + x;
  }

  // Calculate the cross product of 2 3d vectors
  // Used when calculating the surface normal of a triangle
  function cross(v1: number[], v2: number[]): number[] {
    return [
      v1[Y] * v2[Z] - v1[Z] * v2[Y],
      v1[Z] * v2[X] - v1[X] * v2[Z],
      v1[X] * v2[Y] - v1[Y] * v2[X],
    ];
  }

  // Calculate the dot product of 2 3d vectors
  function dot(v1: number[], v2: number[]): number {
    return v1[X] * v2[X] + v1[Y] * v2[Y] + v1[Z] * v2[Z];
  }

  // Calculate the length of a 3d vector
  function len(v: number[]): number {
    return Math.sqrt(v[X] * v[X] + v[Y] * v[Y] + v[Z] * v[Z]);
  }

  // Subtract 2 3d vectors
  function sub(v1: number[], v2: number[]): number[] {
    return [v1[X] - v2[X], v1[Y] - v2[Y], v1[Z] - v2[Z]];
  }

  // Scale a 3d vector
  function mul(v: number[], s: number): number[] {
    return [v[X] * s, v[Y] * s, v[Z] * s];
  }

  // Normalise a 3d vector
  function norm(v: number[]): number[] {
    const l = len(v);
    if (l !== 0) {
      return [v[X] / l, v[Y] / l, v[Z] / l];
    }
    return v;
  }

  // Linear interpolation from a to b
  function lerp(a: number, b: number, i: number): number {
    return (1 - i) * a + i * b;
  }

  // Clamp a value between min and max
  function clamp(a: number, min: number, max: number): number {
    return Math.min(Math.max(a, min), max);
  }

  // Generate an rgb colour string from values
  function rgb(r: number, g: number, b: number): string {
    return (
      'rgb(' + Math.floor(r) + ',' + Math.floor(g) + ',' + Math.floor(b) + ')'
    );
  }

  // Start the render loop
  function loop(): void {
    handleInput();
    update(1 / 60);
    draw(context!);
    window.requestAnimationFrame(loop);
  }
  initialise();
  loop();
};

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Intent } from './intent';
import { User } from './user-model';

export type SubscriptionPeriod = 'monthly' | 'annual';

export type SubscriptionPlan = {
  id: string;
  name: string;
  backgroundColour: string;
  icon: IconDefinition;
  banner?: string;
  bannerColour?: string;
  buttonIntent?: Intent;
  monthlyPrice: string | null;
  annualPrice: string | null;
  features: string[];
};

export type Subscription = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  user?: User;
  plan: {
    id: string;
    name: string;
    monthlyPrice: number;
    annualPrice: number;
    rateLimit: number | null;
    maxRequestsPerMinute: number | null;
    maxLists: number | null;
    maxItemsPerList: number | null;
    maxIndexesPerList: number | null;
    maxItemSize: number | null;
    maxTokens: number | null;
  };
  period: SubscriptionPeriod | null;
  currentPeriodEndAt: Date | null;
  customerId?: string | null;
  subscriptionId?: string | null;
  lastInvoiceId?: string | null;
  lastPaymentSucceededAt?: Date | null;
  lastPaymentFailedAt?: Date | null;
  activated?: boolean;
};

export type SubscriptionStats = {
  maxRequestsPerMinute: number;
  totalLists: number;
  maxItemsPerList: number;
  maxIndexesPerList: number;
  maxItemSize: number;
  totalTokens: number;
};

export type SubscriptionPricesResponse = Record<
  string,
  {
    plan: string;
    period: SubscriptionPeriod;
    amount: number;
  }
>;

export type UserSubscriptionForAdmin = {
  subscription: Subscription;
  stats?: SubscriptionStats;
  stripeSubscriptions?: any;
};

export type CurrentUserSubscription = {
  subscription: Subscription;
  stats: SubscriptionStats;
  nextInvoice: any;
};

export type SubscribeResponse = {
  amount: number;
  plan: string;
  period: SubscriptionPeriod;
  clientSecret: string;
};

export const SubscriptionPlanQueryParam = {
  encode: (value?: string | null): string | undefined =>
    value ? (value as string) : undefined,

  decode: (value?: string | (string | null)[] | null): string | undefined =>
    value && ['developer', 'enterprise'].includes(value as any)
      ? value.toString()
      : undefined,
};

export const SubscriptionPeriodQueryParam = {
  encode: (value?: SubscriptionPeriod | null): string | undefined =>
    value ? (value as string) : undefined,

  decode: (
    value?: string | (string | null)[] | null
  ): SubscriptionPeriod | undefined =>
    value && ['monthly', 'annual'].includes(value as any)
      ? (value as SubscriptionPeriod)
      : undefined,
};

export const subscriptionOrderFields = [
  'createdAt',
  'updatedAt',
  'userId',
  'planId',
  'period',
  'customerId',
  'subscriptionId',
  'lastInvoiceId',
  'lastPaymentSucceededAt',
  'lastPaymentFailedAt',
  'currentPeriodEndAt',
  'activated',
] as const;

export type SubscriptionOrderField = (typeof subscriptionOrderFields)[number];

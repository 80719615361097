import { ReactElement, ReactNode } from 'react';
import { Value } from '.';
import classNames from '../utilities/class-names';
import './ValueList.scss';

type ValueListProps = {
  className?: string;
  title?: string;
  children?: ReactNode;
  values?: Record<string, any>;
  inline?: boolean;
  highlightAltRows?: boolean;
  fullWidth?: boolean;
  [key: string]: any;
};

export function ValueList({
  className,
  title,
  children,
  values,
  inline = false,
  highlightAltRows = false,
  fullWidth = false,
  ...props
}: ValueListProps): ReactElement {
  return (
    <>
      {title && <h3>{title}</h3>}
      <table
        className={classNames(
          'value-list',
          inline ? 'value-list-inline' : '',
          highlightAltRows ? 'value-list-highlight-alt-rows' : '',
          fullWidth ? 'value-list-full-width' : '',
          className
        )}
        {...props}
      >
        <tbody>
          {values &&
            Object.entries(values).map(([key, value]) =>
              value !== undefined ? (
                <Value key={key} label={key} value={value} />
              ) : (
                <></>
              )
            )}
          {children}
        </tbody>
      </table>
    </>
  );
}

import dayjs from 'dayjs';
import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import config from '../config';
import { useAuth } from '../hooks/auth';
import { useLocalStorage } from '../hooks/local-storage';
import {
  Error as ApiError,
  BulletinContextState,
  BulletinData,
} from '../types';

export const BulletinContext = createContext<BulletinContextState | undefined>(
  undefined
);

export function BulletinProvider({
  children,
}: {
  children?: ReactNode;
}): ReactElement {
  let location = useLocation();

  const { token, loggedIn } = useAuth();

  const [pending, setPending] = useState(false);
  const [bulletinData, setBulletinData] = useState<BulletinData | null>(null);
  const [cachedBulletinData, setCachedBulletinData] = useLocalStorage(
    'bulletin',
    null
  );
  const [error, setError] = useState<Error | null>(null);
  const [lastRefreshAt, setLastRefreshAt] = useState<Date | null>(null);

  const fetchBulletinData = useCallback(async (): Promise<void> => {
    if (!loggedIn) {
      return;
    }

    setPending(true);
    setError(null);

    fetch(`${config.api.url}/dashboard/bulletin`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token ?? '',
      },
    })
      .then(async response => {
        setPending(false);

        if (!response.ok) {
          setError(new Error(((await response.json()) as ApiError).message));
          return;
        }

        const data = (await response.json()) as BulletinData;

        setBulletinData(data);
        setCachedBulletinData(data);
        setError(null);
      })
      .catch(error => {
        setPending(false);
        setError(error);
      });
  }, [token, loggedIn, setCachedBulletinData]);

  const refresh = useCallback(
    async (force: boolean = false): Promise<void> => {
      const refreshInterval = Math.max(1, config.bulletinRefreshInterval);

      if (
        force ||
        !cachedBulletinData ||
        !lastRefreshAt ||
        dayjs(lastRefreshAt).isBefore(
          dayjs().subtract(refreshInterval, 'seconds')
        )
      ) {
        await fetchBulletinData();
        setLastRefreshAt(new Date());
      }

      setBulletinData(cachedBulletinData);
    },
    [fetchBulletinData, lastRefreshAt, cachedBulletinData]
  );

  useEffect(() => {
    refresh();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <BulletinContext.Provider
      value={{
        pending,
        data: bulletinData,
        error,
        refresh,
      }}
    >
      {children}
    </BulletinContext.Provider>
  );
}

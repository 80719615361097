import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import Login from '../routes/Login';

type ProtectedRouteProps = {
  children: ReactElement;
  requireLoggedIn?: boolean;
  requireAdmin?: boolean;
  redirectPath?: string;
};

export function ProtectedRoute({
  children,
  requireLoggedIn,
  requireAdmin,
  redirectPath,
}: ProtectedRouteProps): ReactElement {
  const { loggedIn, databaseUser: user } = useAuth();

  if (loggedIn === null) {
    return <Login />;
  }

  return loggedIn === !!requireLoggedIn &&
    (requireAdmin === undefined || user?.admin === requireAdmin) ? (
    children
  ) : (
    <Navigate to={redirectPath ?? '/'} />
  );
}

import {
  IconDefinition,
  faCheck,
  faCircleNotch,
  faExclamation,
  faInfo,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, ReactNode } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { Intent } from '../types';
import classNames from '../utilities/class-names';
import './Button.scss';

type ButtonStyle = {
  icon: IconDefinition;
  className: string;
};

const buttonStyles: {
  [key in Intent]: ButtonStyle;
} = {
  info: {
    icon: faInfo,
    className: 'button-info',
  },
  success: {
    icon: faCheck,
    className: 'button-success',
  },
  warning: {
    icon: faExclamation,
    className: 'button-warning',
  },
  danger: {
    icon: faTimes,
    className: 'button-danger',
  },
};

export type ButtonProps = {
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  intent?: Intent;
  icon?: IconDefinition;
  label?: string;
  children?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  secondary?: boolean;
  minimal?: boolean;
  lightMode?: boolean;
  link?: To | -1;
  linkHash?: string;
  linkOpensNewTab?: boolean;
  onClick?: () => void;
  [key: string]: any;
};

export function Button({
  className,
  type = 'button',
  intent,
  icon,
  label,
  children,
  loading,
  disabled,
  secondary,
  minimal,
  lightMode,
  link,
  linkHash,
  linkOpensNewTab,
  onClick,
  ...props
}: ButtonProps): ReactElement {
  const navigate = useNavigate();

  let style: ButtonStyle | null = null;
  if (intent) {
    style = buttonStyles[intent];
  }

  let actualIcon = icon ?? style?.icon;
  if (loading) {
    actualIcon = faCircleNotch;
  }

  return (
    <button
      type={type}
      disabled={!!disabled}
      className={classNames(
        'button',
        secondary ? 'button-secondary' : 'button-primary',
        minimal ? 'button-minimal' : '',
        lightMode ? 'button-light' : '',
        style?.className,
        className,
        label ? '' : 'button-icon-only'
      )}
      onClick={() => {
        onClick?.();

        if (link) {
          const actualLink =
            linkHash && link !== -1 ? `${link}#${linkHash}` : link;

          if (linkOpensNewTab) {
            window.open(actualLink as string, '_blank');
            return;
          }

          navigate(actualLink as To);
        }
      }}
      {...props}
    >
      {actualIcon && (
        <span className="button-icon">
          <FontAwesomeIcon icon={actualIcon} spin={!!loading} />
        </span>
      )}
      {label ?? ''}
      {children}
    </button>
  );
}

import { ReactElement, ReactNode } from 'react';
import { DocumentationContentsMenu } from '../components';
import classNames from '../utilities/class-names';
import { Dashboard } from './Dashboard';
import './Documentation.scss';

type DocumentationProps = {
  className?: string;
  children?: ReactNode;
  [key: string]: any;
};

export function Documentation({
  className,
  children,
  ...props
}: DocumentationProps): ReactElement {
  return (
    <Dashboard
      className={classNames('documentation-layout', className)}
      {...props}
    >
      <DocumentationContentsMenu />

      <div className="documentation-content">{children}</div>
    </Dashboard>
  );
}

export enum SettingComponentType {
  TextInput = 'text-input',
  NumericInput = 'numeric-input',
  TextArea = 'text-area',
  Switch = 'switch',
  Checkbox = 'checkbox',
  JsonEditor = 'json-editor',
  Select = 'select',
}

export type Setting = {
  key: string;
  value: any;
  schema: Record<string, any> | null;
  component: SettingComponentType;
};

import {
  IconDefinition,
  faArrowDownShortWide,
  faArrowDownWideShort,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Tag } from '.';
import { IndexDefaultOrderDirection } from '../types';
import classNames from '../utilities/class-names';

type IndexDefaultOrderDirectionTagStyle = {
  icon: IconDefinition;
  className: string;
  label: string;
};

const indexDefaultOrderDirectionTagStyles: {
  [key in IndexDefaultOrderDirection]: IndexDefaultOrderDirectionTagStyle;
} = {
  asc: {
    icon: faArrowDownShortWide,
    className: 'index-default-order-direction-tag-asc',
    label: 'Ascending',
  },
  desc: {
    icon: faArrowDownWideShort,
    className: 'index-default-order-direction-tag-desc',
    label: 'Descending',
  },
};

type IndexDefaultOrderDirectionTagProps = {
  defaultOrderDirection: IndexDefaultOrderDirection;
};

export function IndexDefaultOrderDirectionTag({
  defaultOrderDirection,
}: IndexDefaultOrderDirectionTagProps): ReactElement {
  let { icon, className, label } =
    indexDefaultOrderDirectionTagStyles[defaultOrderDirection];

  return (
    <Tag
      show
      className={classNames('index-default-order-direction-tag', className)}
      icon={icon}
    >
      {label}
    </Tag>
  );
}

import { loadStripe, Stripe } from '@stripe/stripe-js';
import config from '../config';

let stripe: Stripe | null = null;

if (config.stripe.publicKey) {
  stripe = await loadStripe(config.stripe.publicKey);
}

export default stripe;
